import { useTranslation } from "react-i18next";
import {
  FaRegImage,
  FaVideo,
  FaLocationDot,
  FaMicrophone,
  FaFile,
} from "react-icons/fa6";
import { PiStickerFill } from "react-icons/pi";
import { FaRegUser, FaHandPointer } from "react-icons/fa";
import { GrUnorderedList } from "react-icons/gr";

import { Interactivetype, Message } from "@@types/message";

import MsgType from "./MsgType";
import { memo, useEffect, useState } from "react";
import { format } from "date-fns";
import { getAudioDuration } from "@fuse/utils/getAudioDuration";
import { hasArabicCharacters } from "@fuse/utils/hasArabicCharacters";
import { Typography } from "@mui/material";

type MsgTypeMapperProps = {
  msg?: Message;
};
const MsgTypeMapper = ({ msg }: MsgTypeMapperProps) => {
  const [audioDuration, setAudioDuration] = useState<string | null>(null);
  const { t } = useTranslation();

  let jsxElement: JSX.Element | null = null;

  const interactiveMsgBody = () => {
    let msgBody;
    let jsondata: any;

    if (msg?.senderJsonData) {
      jsondata = msg?.senderJsonData?.interactive;
    } else {
      jsondata =
        msg?.jsondata?.entry?.[0]?.changes?.[0]?.value?.messages?.[0]
          ?.interactive;
    }

    if (!jsondata) return "";
    const interactivetype = jsondata?.type as Interactivetype;

    if (interactivetype === "location_request_message")
      return jsondata?.body?.text;

    if (["button", "list", "flow", "cta_url"].includes(interactivetype)) {
      const headerType = jsondata?.header?.type;
      const header = jsondata?.header;
      const body = jsondata?.body;
      const footer = jsondata?.footer;

      if (header) {
        if (headerType === "text") {
          msgBody = jsondata?.body?.text;
        } else if (headerType === "image") {
          msgBody = t("sideItems.image");
        } else if (headerType === "video") {
          msgBody = t("sideItems.video");
        } else if (headerType === "document") {
          msgBody = jsondata?.header?.filename;
        }
      }

      if (body?.text) {
        msgBody = `${body?.text}`;
      } else if (footer?.text) {
        msgBody = ` ${footer?.text}`;
      }
    } else if (interactivetype === "button_reply") {
      msgBody = jsondata?.button_reply?.title;
    } else if (interactivetype === "list_reply") {
      msgBody = jsondata?.list_reply?.title;
    } else if (interactivetype === "nfm_reply") {
      const replyJson = Object.entries(
        JSON.parse(jsondata.nfm_reply.response_json)
      );
      msgBody = replyJson.map(([key, value]) => {
        const isDate = (key as string).includes("date");
        let dateString;
        if (isDate) {
          const timestampNumber = Number(value);
          const date = new Date(timestampNumber);
          if (!isNaN(date.getTime())) {
            dateString = format(date, "yyyy/MM/dd");
          } else {
            dateString = value;
          }
        }
        return `${key.replace("_", " ")}: ${isDate ? dateString : value} `;
      });
    } else if (
      ["product", "product_list", "catalog_message"].includes(interactivetype)
    ) {
      msgBody = jsondata?.body?.text;
    }

    return msgBody;
  };

  useEffect(() => {
    if (msg?.fileData?.path && msg.msgtype === "audio") {
      getAudioDuration(msg.fileData?.path)
        .then((res) => setAudioDuration(res))
        .catch((err) => console.error(err));
    }
  }, [msg]);

  const Text = ({ text }: { text?: string }) => (
    <div className="grid grid-cols-1">
      <Typography
        className="text-ellipsis whitespace-nowrap w-full overflow-hidden !text-[1.5rem]"
        dir={hasArabicCharacters(text)}
      >
        {text}
      </Typography>
    </div>
  );

  switch (msg?.msgtype) {
    case "image":
      jsxElement = (
        <div className="flex gap-[0.75rem]">
          <MsgType
            msgType={t("sideItems.image")}
            icon={FaRegImage}
            showMsgType={!msg?.text_body}
          />
          <Text text={msg.text_body} />
        </div>
      );
      break;
    case "video":
      jsxElement = (
        <div className="flex gap-[0.75rem]">
          <MsgType
            msgType={t("sideItems.video")}
            icon={FaVideo}
            showMsgType={!msg?.text_body}
          />
          <Text text={msg.text_body} />
        </div>
      );
      break;
    case "location":
      jsxElement = (
        <MsgType
          showMsgType
          msgType={t("sideItems.location")}
          icon={FaLocationDot}
        />
      );
      break;
    case "audio":
      jsxElement = (
        <MsgType
          showMsgType
          msgType={audioDuration ? audioDuration : t("sideItems.audio")}
          icon={FaMicrophone}
        />
      );
      break;
    case "contacts":
      jsxElement = (
        <div className="flex gap-[0.75rem] items-center">
          <MsgType showMsgType icon={FaRegUser} />
          <div className="grow grid grid-cols-1">
            {!!(msg as Message).contacts_message && (
              <Typography
                className="text-ellipsis whitespace-nowrap overflow-hidden"
                dir={hasArabicCharacters(
                  (msg as Message)?.contacts_message[0]?.name?.formatted_name
                )}
              >
                {(msg as Message)?.contacts_message[0]?.name?.formatted_name}
              </Typography>
            )}
          </div>
        </div>
      );
      break;
    case "document":
      jsxElement = (
        <div className="flex gap-7">
          <div className="flex gap-[0.75rem] items-center">
            <MsgType showMsgType icon={FaFile} />
            {!msg.text_body && (
              <div className="grow grid grid-cols-1 w-full">
                <Typography className="text-ellipsis whitespace-nowrap overflow-hidden">
                  {msg?.fileData?.name ? msg?.fileData?.name : ""}
                </Typography>
              </div>
            )}
          </div>
          <Text text={msg.text_body} />
        </div>
      );
      break;
    case "sticker":
      jsxElement = (
        <MsgType
          showMsgType
          msgType={t("sideItems.sticker")}
          icon={PiStickerFill}
        />
      );
      break;
    case "interactive":
      jsxElement = (
        <div className="flex gap-[0.75rem] items-center">
          <MsgType msgType={t("sideItems.sticker")} icon={FaHandPointer} />
          <div
            className="grow grid grid-cols-1 w-full"
            dir={hasArabicCharacters(interactiveMsgBody())}
          >
            <Typography className="text-ellipsis whitespace-nowrap overflow-hidden">
              {interactiveMsgBody()}
            </Typography>
          </div>
        </div>
      );
      break;
    case "text":
      jsxElement = <Text text={msg.text_body} />;
      break;
    case "template":
      // eslint-disable-next-line no-case-declarations
      const body = msg?.messages_company?.templateComponents?.find(
        (component: any) => component.type === "BODY"
      );

      // eslint-disable-next-line no-case-declarations
      let bodyText = body?.text;
      if (msg?.templateVariables && bodyText) {
        Object.keys(msg?.templateVariables).forEach((key) => {
          bodyText = bodyText.replace(
            new RegExp(`{{\\s*${key}\\s*}}`, "g"),
            msg?.templateVariables[key]
          );
        });
      }
      jsxElement = <Text text={bodyText} />;
      break;
    case "button":
      jsxElement = (
        <div className="flex gap-[0.75rem] items-center">
          <MsgType icon={FaHandPointer} />
          <Text text={msg.text_body} />
        </div>
      );
      break;
    case "order":
      jsxElement = (
        <div className="flex gap-[0.75rem] items-center">
          <MsgType
            icon={GrUnorderedList}
            showMsgType
            msgType={t("sideItems.order")}
          />
        </div>
      );
      break;
  }

  return <div className="w-fit">{jsxElement}</div>;
};

export default memo(MsgTypeMapper);
