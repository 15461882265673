import { useTranslation } from "react-i18next";

const AnotherConnection = () => {
  const { t } = useTranslation();
  return (
    <div className="h-screen w-screen flex items-center justify-center bg-[#f1f5f9]">
      <div className="text-center">
        <p className="text-2xl font-bold">{t("annotherConnection.title")}</p>
        <p>{t("annotherConnection.subTitle")}</p>
      </div>
    </div>
  );
};

export default AnotherConnection;
