import { TeamMember } from "@@types/teamMember";
import ApiService from "./ApiService";

export const addNewMemberApi = (data: TeamMember) => {
  return ApiService.fetchData<TeamMember>({
    url: "/users",
    method: "post",
    data,
  });
};
export const updateMemberApi = (data: TeamMember) => {
  return ApiService.fetchData<TeamMember>({
    url: `/users/${data.id}`,
    method: "put",
    data,
  });
};

export const getMembersApi = () => {
  return ApiService.fetchData<TeamMember[]>({
    url: "/users",
    method: "get",
  });
};
export const getMemberApi = (id: number) => {
  return ApiService.fetchData<TeamMember>({
    url: `/users/${id}`,
    method: "get",
  });
};
export const deleteMemberApi = (id: number) => {
  return ApiService.fetchData<TeamMember>({
    url: `/users/${id}`,
    method: "delete",
  });
};
