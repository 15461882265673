export const hasArabicCharacters = (text?: string): 'rtl' | 'ltr' => {
    if (!text || typeof text !== 'string' || !text?.trim()) return 'ltr'
    const inputValueWithoutSpecialChars = text
        .replaceAll(/[^a-zA-Z\u0600-\u06FF]/g, '')
        .trim()

    const arabicRegex = /[\u0600-\u06FF]/

    const isArabic = arabicRegex.test(
        inputValueWithoutSpecialChars ? inputValueWithoutSpecialChars[0] : '',
    )

    return isArabic ? 'rtl' : 'ltr'
}
