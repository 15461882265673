import {
  cancelAppointmentService,
  createAppointment,
  createLabelApi,
  deleteAppointmentService,
  deleteLabelApi,
  getAllAppointment,
  getAllLabels,
  getTodatAppointment,
  updateAppointmentService,
  updateLabelApi,
} from "@@services/AppointmentService";
import { Appointment, Label } from "@@types/appointment";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

type Filter = {
  label?: Label;
  isCancel?: boolean;
};

export type AppointmentSlice = {
  appointments: Array<Appointment>;
  todayAppointments: Array<Appointment>;
  filters: Filter;
  labels: Array<Label>;
};

export const getAppointments = createAsyncThunk(
  "appointment/getAppointments",
  async () => {
    const data = await getAllAppointment();
    return data.data;
  }
);
export const getLabels = createAsyncThunk("appointment/getLabels", async () => {
  const data = await getAllLabels();
  return data.data;
});

export const createLabel = createAsyncThunk(
  "appointment/createLabel",
  async (label: Label) => {
    const data = await createLabelApi(label);
    return data.data;
  }
);
export const updateLabel = createAsyncThunk(
  "appointment/updateLabel",
  async (label: Label) => {
    const data = await updateLabelApi(label);
    return data.data;
  }
);
export const deleteLabel = createAsyncThunk(
  "appointment/deleteLabel",
  async (label: Label) => {
    await deleteLabelApi(label);
    return label;
  }
);

export const getTodayAppointments = createAsyncThunk(
  "appointment/getTodayAppointments",
  async () => {
    const data = await getTodatAppointment();
    return data.data;
  }
);
export const createNewAppointment = createAsyncThunk(
  "appointment/createNewAppointment",
  async (dataAppointment: Appointment) => {
    const data = await createAppointment(dataAppointment);
    return data.data;
  }
);
export const updateAppointmentApi = createAsyncThunk(
  "appointment/updateAppointmentApi",
  async (dataAppointment: Appointment) => {
    const data = await updateAppointmentService(dataAppointment);
    return data.data;
  }
);

export const cancelAppointmentApi = createAsyncThunk(
  "appointment/cancelAppointmentApi",
  async (dataAppointment: Appointment) => {
    const data = await cancelAppointmentService(dataAppointment.id as number);
    return data.data;
  }
);

export const deleteAppointmentApi = createAsyncThunk(
  "appointment/deleteAppointmentApi",
  async (id: number) => {
    await deleteAppointmentService(id);
    return id;
  }
);

const initialState: AppointmentSlice = {
  appointments: [],
  todayAppointments: [],
  filters: {},
  labels: [],
};
export const AppointmentSlice = createSlice({
  name: "appointment",
  initialState,
  reducers: {
    cancelAppointment(state, action: PayloadAction<Appointment>) {
      const index = state.appointments.findIndex(
        (appointment) => appointment.id === action.payload.id
      );
      state.appointments[index] = {
        ...state.appointments[index],
        isCancel: true,
      };
    },
    deleteAppointment(state, action: PayloadAction<number>) {
      const index = state.appointments.findIndex(
        (appointment) => appointment.id === action.payload
      );
      state.appointments.splice(index, 1);
    },
    addAppointment(state, action: PayloadAction<Appointment>) {
      state.appointments.push(action.payload);
    },
    updateAppointment(state, action: PayloadAction<Appointment>) {
      const index = state.appointments.findIndex(
        (appointment) => appointment.id === action.payload.id
      );
      state.appointments[index] = action.payload;
    },
    setFilters(state, action: PayloadAction<Filter>) {
      state.filters = action.payload;
    },
    newLabel(state, action: PayloadAction<Label>) {
      state.labels.push(action.payload);
    },
    updateLabelData(state, action: PayloadAction<Label>) {
      const index = state.labels.findIndex(
        (label) => label.id === action.payload.id
      );
      state.labels[index] = action.payload;
      state.appointments = state.appointments.map((appointment) => ({
        ...appointment,
        label:
          appointment?.label?.id === action.payload.id
            ? action.payload
            : appointment.label,
      }));
    },
    deleteLabelData(state, action: PayloadAction<Label>) {
      const index = state.labels.findIndex(
        (label) => label.id === action.payload.id
      );
      state.labels.splice(index, 1);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAppointments.fulfilled, (state, action) => {
        state.appointments = action.payload;
      })
      .addCase(getTodayAppointments.fulfilled, (state, action) => {
        state.todayAppointments = action.payload;
      })
      .addCase(createNewAppointment.fulfilled, (state, action) => {
        state.appointments.push(action.payload);
      })
      .addCase(updateAppointmentApi.fulfilled, (state, action) => {
        const index = state.appointments.findIndex(
          (appointment) => appointment.id === action.payload.id
        );
        state.appointments[index] = action.payload;
      })
      .addCase(cancelAppointmentApi.fulfilled, (state, action) => {
        const index = state.appointments.findIndex(
          (appointment) => appointment.id === action.payload.id
        );
        state.appointments[index] = action.payload;
      })
      .addCase(deleteAppointmentApi.fulfilled, (state, action) => {
        const index = state.appointments.findIndex(
          (appointment) => appointment.id === action.payload
        );
        state.appointments.splice(index, 1);
      })
      .addCase(getLabels.fulfilled, (state, action) => {
        state.labels = action.payload;
      })
      .addCase(createLabel.fulfilled, (state, action) => {
        state.labels.push(action.payload);
      })
      .addCase(updateLabel.fulfilled, (state, action) => {
        const index = state.labels.findIndex(
          (label) => label.id === action.payload.id
        );
        state.labels[index] = action.payload;
        state.appointments = state.appointments.map((appointment) => ({
          ...appointment,
          label:
            appointment?.label?.id === action.payload.id
              ? action.payload
              : appointment.label,
        }));
      })
      .addCase(deleteLabel.fulfilled, (state, action) => {
        state.labels = state.labels.filter(
          (label) => label.id !== action.payload.id
        );
      });
  },
});

export const appointments = (state: RootState) =>
  state.appointment.appointments;
export const labels = (state: RootState) => state.appointment.labels;
export const appointmentsFilters = (state: RootState) =>
  state.appointment.filters;

export const todayAppointments = (state: RootState) =>
  state.appointment.todayAppointments;

export const {
  cancelAppointment,
  deleteAppointment,
  addAppointment,
  updateAppointment,
  setFilters,
  newLabel,
  updateLabelData,
} = AppointmentSlice.actions;
export default AppointmentSlice.reducer;
