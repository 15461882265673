import {
  getTeamChatsApi,
  getTeamMessagesApi,
} from "@@services/TeamChatService";
import { TeamMessage, UsersChat } from "@@types/teamChat";
import { TeamMember } from "@@types/teamMember";
import {
  PayloadAction,
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";
import { selectUser } from "./userSlice";
import { RootState } from "../store";
import { addMember, deleteMember } from "./teamListSlice";

export type TeamChatSlice = {
  users: Array<UsersChat & { disableFetch?: boolean; loading?: boolean }>;

  messages: Array<TeamMessage>;
  selectedTeamChat?: number;
};

const initialState: TeamChatSlice = { users: [], messages: [] };

export const getTeamChats = createAsyncThunk(
  "teamChatSlice/getTeamChatsApi",
  async () => {
    const response = await getTeamChatsApi();
    return response.data;
  }
);
export const getTeamMessages = createAsyncThunk(
  "teamChatSlice/getTeamMessages",
  async (_, { getState }) => {
    const state = getState() as RootState;

    const activeChatData = getSelectedTeamChat(state);
    const messages = userMessages(state);
    const lastMsgDate = messages.length
      ? messages[messages.length - 1].createdAt
      : undefined;
    const response = await getTeamMessagesApi(
      activeChatData.user.id,
      lastMsgDate
    );
    return { msgs: response.data, userId: activeChatData.user.id };
  }
);
export const teamChatSlice = createSlice({
  name: "teamChatSlice",
  initialState,
  reducers: {
    setSlectedTeamChat(state, action: PayloadAction<UsersChat>) {
      if (action.payload) {
        state.selectedTeamChat = action.payload.user.id;
      } else {
        state.selectedTeamChat = undefined;
      }
    },
    setUserStatus(
      state,
      action: PayloadAction<{ user: TeamMember; isOnline: boolean }>
    ) {
      const userIndex = state.users.findIndex(
        (u) => u.user.id === action.payload.user.id
      );
      if (userIndex !== -1) {
        state.users[userIndex].isOnline = action.payload.isOnline;
      }
    },
    createTeamMsg(state, action: PayloadAction<TeamMessage>) {
      const userIndex = state.users.findIndex(
        (u) => u.user.id === state.selectedTeamChat
      );
      state.users[userIndex].lastMessageDate = new Date().toISOString();
      state.messages.unshift({
        ...action.payload,
        createdAt: new Date().toISOString(),
      });
    },
    updateMsgAfterSend(
      state,
      action: PayloadAction<TeamMessage & { localId: number }>
    ) {
      const index = state.messages.findIndex(
        (msg) => msg.id === action.payload.localId
      );
      if (index !== -1) {
        state.messages[index] = { ...action.payload };
      }
    },
    reciveMsg(state, action: PayloadAction<TeamMessage>) {
      state.messages.unshift(action.payload);
    },
    updateTeamMsg(state, action: PayloadAction<TeamMessage>) {
      const index = state.messages.findIndex(
        (msg) => msg.id === action.payload.id
      );
      if (index !== -1) {
        state.messages[index] = { ...action.payload };
      }
    },
    incrementUnReadTeamMsg(state, action: PayloadAction<number>) {
      const chat = state.users.find((u) => u.user.id === action.payload);

      chat.unReadCount += 1;
    },
    zeroingUnReadTeamMsgs(state, action: PayloadAction<number>) {
      const chat = state.users.find((u) => u.user.id === action.payload);
      chat.unReadCount = 0;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTeamChats.fulfilled, (state, action) => {
        state.users = action.payload;
      })
      .addCase(addMember.fulfilled, (state, action) => {
        state.users.push({
          user: action.payload,
          unReadCount: 0,
          isOnline: false,
        });
      })
      .addCase(deleteMember.fulfilled, (state, action) => {
        state.users = state.users.filter(
          (user) => user.user.id !== action.payload.id
        );
      })
      .addCase(getTeamMessages.pending, (state, action) => {
        const chat = state.users.find(
          (user) => user.user.id === state?.selectedTeamChat
        );
        chat.loading = true;
      })
      .addCase(getTeamMessages.fulfilled, (state, action) => {
        state.messages = [...state.messages, ...action.payload.msgs];
        const index = state.users.findIndex(
          (user) => user.user.id === action.payload.userId
        );

        if (action.payload.msgs.length < 20) {
          if (index !== -1) {
            state.users[index].disableFetch = true;
          }
        }
        state.users[index].loading = false;
      });
  },
  selectors: {
    getUsersChats: (state) => state.users,
    getSelectedTeamChat: (state) =>
      state.users.find((user) => user.user.id === state.selectedTeamChat),
    getMessages: (state) => state.messages,
  },
});

export const { getUsersChats, getSelectedTeamChat, getMessages } =
  teamChatSlice.selectors;

export const userMessages = createSelector(
  [getMessages, getSelectedTeamChat, selectUser],
  (messages, activeUser, currentUser) => {
    return messages.filter(
      (message) =>
        (message.from.id === activeUser.user.id &&
          message.to.id === currentUser.id) ||
        (message.to.id === activeUser.user.id &&
          message.from.id === currentUser.id)
    );
  }
);
export const {
  setSlectedTeamChat,
  setUserStatus,
  createTeamMsg,
  updateMsgAfterSend,
  reciveMsg,
  updateTeamMsg,
  incrementUnReadTeamMsg,
  zeroingUnReadTeamMsgs,
} = teamChatSlice.actions;
