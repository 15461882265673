import ApiService from "./ApiService";
import { ConversationType } from "@@types/chat";

export const getAllConversationTypeApi = () => {
  return ApiService.fetchData<ConversationType[]>({
    url: "/conversations-types",
    method: "get",
  });
};

export const createConversationTypeApi = (data) => {
  return ApiService.fetchData<ConversationType>({
    url: "/conversations-types",
    method: "post",
    data,
  });
};

export const updateConversationTypeApi = (data) => {
  return ApiService.fetchData<ConversationType>({
    url: `/conversations-types/${data.id}`,
    method: "put",
    data,
  });
};
export const deleteConversationTypeApi = (id) => {
  return ApiService.fetchData<ConversationType>({
    url: `/conversations-types/${id}`,
    method: "delete",
  });
};
