import { Role } from "@@types/roles";
import { lazy } from "react";

const TeamApp = lazy(() => import("./TeamApp"));

/**
 * The chat app config.
 */
const TeamAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "team",
      element: <TeamApp />,
      auth: [Role.TEAM_MANAGEMENT],
    },
  ],
};

export default TeamAppConfig;
