import { BroadcastMsg } from '@/@types/broadcast'
import ApiService from './ApiService'
import { Message } from '@/@types/message'

export const createBroadcastService = async (data?: any) => {
    const form = new FormData()
    const newData = Object.entries(data)
    newData.forEach(([key, value]) => {
        form.append(key, value as any)
    })
    return ApiService.fetchData({
        url: `/bulkfiles`,
        method: 'post',
        data: form,
    })
}
export const updateBroadcastService = async (data?: any) => {
    return ApiService.fetchData<BroadcastMsg>({
        url: `/bulk-messages/${data.id}`,
        method: 'put',
        data: data,
    })
}
export const getBroadcastsService = async (page: number) => {
    return ApiService.fetchData<BroadcastMsg[]>({
        url: `/bulk-messages/?page=${page}&limit=20`,
        method: 'get',
    })
}

export const sendBroadcastMsg = async (data: {
    templateMessage: number
    contactsGroup: number
    sendDateTime?: string
    selectedLanguage?: string
    message_from: string
}) => {
    return ApiService.fetchData<BroadcastMsg>({
        url: `/bulk-messages`,
        method: 'post',
        data,
    })
}
export const getBroadcastMsgsService = async (page: number) => {
    return ApiService.fetchData<BroadcastMsg[]>({
        url: `/bulk-messages/?page=${page}`,
        method: 'get',
    })
}
export const getBroadcastMsgsListService = async (
    id: number,
    status: string,
) => {
    return ApiService.fetchData<Message[]>({
        url: `/messages/bulk/${id}/${status}`,
        method: 'get',
    })
}

export const getBroadcastMsgsDateService = async (id: number, date: string) => {
    return ApiService.fetchData<
        {
            hour: number
            replied: number
            optout: number
            sent: number
            read: number
            delivered: number
        }[]
    >({
        url: `/messages/bulk-by-date/${id}/${date}`,
        method: 'get',
    })
}
