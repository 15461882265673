export enum Role {
  ADMIN = "admin",

  DASHBOARD = "dashboard",
  APPOINTMENT = "appointment",
  CONTACTS = "contacts",
  MESSAGES_COMPANY = "messages_company",
  BULK_MESSAGES = "bulk_messages",
  ALL_CONVERSATIONS = "all_conversations",
  ONLY_ASSIGNED_CONVERSATIONS = "only_assigned_conversations",
  MESSAGES_PAGE = "messages_page",
  TEAM_MANAGEMENT = "team_management",
  BOT_PAGE = "bot_page",
  GPT_PAGE = "gpt_page",
  CONSULTATION_PAGE = "consultation_page",
}
