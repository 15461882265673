import {
  createCompanyMasterMsgs,
  createCompanyMsgs,
  deleteCompanyMsgsLangApi,
  deleteCompanyMsgsMasterApi,
  getCompanyMsgsMaster,
  updateCompanyMsgsApi,
} from "@@services/CompanyMessageService";
import { CompanyMessages } from "@@types/companyMessages";
import { CompanyMessagesMaster } from "@@types/companyMessagesMaster";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";
import { RootState } from "../store";
import { MessageType } from "@@types/message";

type Filter = {
  type?: string[];
  search?: string;
  pagesize: number;
  pageindex: number;
};

export type CompanyMessagesSlice = {
  companyMsgs: Array<CompanyMessagesMaster>;
  filters: Filter;
  companyMsgsLanguages: Array<CompanyMessages>;
  activeCompanyMsgs?: number;
  activeCompanyMsgLanguage?: number;
};

export const getCompanyMessagesApi = createAsyncThunk(
  "companyMessages/getMsgs",
  async () => {
    return await getCompanyMsgsMaster();
  }
);
export const addCompanyMessagesApi = createAsyncThunk(
  "companyMessages/addMsgs",
  async (data: any, { rejectWithValue }) => {
    const res = await createCompanyMasterMsgs(data);
    return res.data;
  }
);
export const addCompanyMessagesLangApi = createAsyncThunk(
  "companyMessages/addMsgsLang",
  async (data: any, { rejectWithValue }) => {
    const res = await createCompanyMsgs(data);
    return res.data;
  }
);
export const updateCompanyMessagesApi = createAsyncThunk(
  "companyMessages/updateMsgs",
  async (data: CompanyMessagesMaster) => {
    return await updateCompanyMsgsApi(data);
  }
);

export const deleteMaster = createAsyncThunk(
  "companyMessages/deleteMaster",
  async (id: number) => {
    await deleteCompanyMsgsMasterApi(id);
    return id;
  }
);
export const deleteLang = createAsyncThunk(
  "companyMessages/deleteLang",
  async (id: number) => {
    await deleteCompanyMsgsLangApi(id);
    return id;
  }
);
const initialState: CompanyMessagesSlice = {
  companyMsgs: [],
  companyMsgsLanguages: [],
  filters: {
    type: [],
    search: "",
    pageindex: 1,
    pagesize: 10,
  },
};

export const companyMessagesSlice = createSlice({
  name: "companyMessagesSlice",
  initialState,
  reducers: {
    addCompanyMsgs(
      state,
      action: PayloadAction<CompanyMessagesMaster | { error: number }>
    ) {
      if (!("error" in action.payload)) {
        state.companyMsgs.push(action.payload);
      }
    },
    deleteCompanyMsgs(state, action: PayloadAction<number>) {
      state.companyMsgs = state.companyMsgs.filter(
        (msg) => msg.id !== action.payload
      );
    },
    updateCompanyMsgs(
      state,
      action: PayloadAction<CompanyMessagesMaster | { error: number }>
    ) {
      if (!("error" in action.payload)) {
        const index = state.companyMsgs.findIndex(
          (msg) => msg.id === (action.payload as CompanyMessagesMaster).id
        );
        state.companyMsgs[index] = {
          ...action.payload,
        };
      }
    },
    updateTemplate(state, action: PayloadAction<CompanyMessages>) {
      const masterIndex = state.companyMsgs.findIndex((msg) =>
        msg.messagessCompany?.find((m) => m.id === action.payload.id)
      );
      if (masterIndex !== -1) {
        const index = state.companyMsgs![
          masterIndex!
        ].messagessCompany?.findIndex((msg) => msg.id === action.payload.id);
        if (index !== -1) {
          state.companyMsgs![masterIndex!].messagessCompany![index!] =
            action.payload;
        } else {
          state.companyMsgs![masterIndex!].messagessCompany?.push(
            action.payload
          );
        }
      }
    },
    setCompanyMsgsFilters(state, action: PayloadAction<Filter>) {
      state.filters = action.payload;
    },
    setCompanyMsgsLanguages(state, action: PayloadAction<CompanyMessages[]>) {
      state.companyMsgsLanguages = action.payload;
    },

    updateCompanyMsgsLanguages(
      state,
      action: PayloadAction<{
        [K in keyof CompanyMessages]: CompanyMessages[K];
      }>
    ) {
      const index = state.companyMsgsLanguages.findIndex(
        (msg) => msg.id === state.activeCompanyMsgLanguage
      );
      if (index !== -1) {
        state.companyMsgsLanguages[index] = {
          ...state.companyMsgsLanguages[index],
          ...action.payload,
        };
      }
    },
    deleteCompanyMsgsLanguages(state, action: PayloadAction<number | string>) {
      state.companyMsgsLanguages = state.companyMsgsLanguages.filter(
        (msg) => msg.id !== action.payload
      );
    },
    createCompanyMsgsLanguages(
      state,
      action: PayloadAction<{ lang: string; msgtype: string }>
    ) {
      const id = "new" as unknown as number;
      state.companyMsgsLanguages = [
        ...state.companyMsgsLanguages,
        {
          id,
          ...action.payload,
        } as CompanyMessages,
      ];
      state.activeCompanyMsgLanguage = id;
    },
    setActiveCompanyMsgs(state, action: PayloadAction<number | undefined>) {
      state.activeCompanyMsgs = action.payload;
    },
    setActiveCompanyMsgLanguage(
      state,
      action: PayloadAction<number | undefined>
    ) {
      state.activeCompanyMsgLanguage = action.payload;
    },
    addCompanyMessagesLang(state, action: PayloadAction<CompanyMessages>) {
      const masterId = state.activeCompanyMsgs;
      const data = action.payload;
      const index = state.companyMsgs.findIndex((msg) => msg.id === masterId);
      state.companyMsgs[index].messagessCompany.push(data);
    },
    updateCompanyMessagesLang(state, action: PayloadAction<CompanyMessages>) {
      const masterId = state.activeCompanyMsgs;
      const data = action.payload;
      const masterIndex = state.companyMsgs.findIndex(
        (msg) => msg.id === masterId
      );
      const msgIndex = state.companyMsgs[
        masterIndex
      ].messagessCompany.findIndex((msg) => msg.id === data.id);
      state.companyMsgs[masterIndex].messagessCompany[msgIndex] = data;
    },
    resetCompanyMessagesLang(state) {
      const masterId = state.activeCompanyMsgs;
      const master = state.companyMsgs.find((msg) => msg.id === masterId);
      state.companyMsgsLanguages = master.messagessCompany;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCompanyMessagesApi.fulfilled, (state, action) => {
        state.companyMsgs = action.payload.data;
      })
      .addCase(addCompanyMessagesApi.fulfilled, (state, action) => {
        state.companyMsgs.push(action.payload as CompanyMessagesMaster);
      })
      .addCase(updateCompanyMessagesApi.fulfilled, (state, action) => {
        const data = action.payload.data as CompanyMessagesMaster;
        const index = state.companyMsgs.findIndex((msg) => msg.id === data.id);
        state.companyMsgs[index] = data;
      })
      .addCase(deleteMaster.fulfilled, (state, action) => {
        const id = action.payload;
        state.companyMsgs = state.companyMsgs.filter((msg) => msg.id !== id);
      })
      .addCase(deleteLang.fulfilled, (state, action) => {
        const id = action.payload;
        const masterIndex = state.companyMsgs.findIndex((msg) =>
          msg.messagessCompany.some((m) => m.id === id)
        );
        state.companyMsgs[masterIndex].messagessCompany = state.companyMsgs[
          masterIndex
        ].messagessCompany.filter((msg) => msg.id !== id);
      })
      .addCase(addCompanyMessagesLangApi.fulfilled, (state, action) => {
        const masterId = state.activeCompanyMsgs;
        const data = action.payload as CompanyMessages;
        const index = state.companyMsgs.findIndex((msg) => msg.id === masterId);
        state.companyMsgs[index].messagessCompany.push(data);
      });
  },
});

export const companyMsgs = (state: RootState, msgtype?: MessageType) => {
  return msgtype
    ? state.companyMessagesSlice.companyMsgs.filter(
        (msg) => msg.msgtype === msgtype
      )
    : state.companyMessagesSlice.companyMsgs;
};

export const companyMsgById = (state: RootState, id?: number) => {
  if (id)
    return state.companyMessagesSlice.companyMsgs.find((msg) => msg.id === id);
};

export const companyMsgsTemplete = (state: RootState) =>
  state.companyMessagesSlice.companyMsgs.filter(
    (msg) => msg.msgtype === "template"
  );

export const companyMsgsFilters = (state: RootState) =>
  state.companyMessagesSlice.filters;

export const companyMsgsLanguages = (state: RootState) =>
  state.companyMessagesSlice.companyMsgsLanguages;

export const activeCompanyMsgsMaster = (state: RootState) =>
  state.companyMessagesSlice.companyMsgs.find(
    (msg) => msg.id === state.companyMessagesSlice.activeCompanyMsgs
  );
export const activeCompanyMsgLanguage = (state: RootState) =>
  state.companyMessagesSlice.companyMsgsLanguages.find(
    (msg) => msg.id === state.companyMessagesSlice.activeCompanyMsgLanguage
  );

export const {
  addCompanyMsgs,
  deleteCompanyMsgs,
  setCompanyMsgsFilters,
  updateTemplate,
  updateCompanyMsgs,
  createCompanyMsgsLanguages,
  updateCompanyMsgsLanguages,
  setCompanyMsgsLanguages,
  deleteCompanyMsgsLanguages,
  setActiveCompanyMsgs,
  setActiveCompanyMsgLanguage,
  addCompanyMessagesLang,
  updateCompanyMessagesLang,
  resetCompanyMessagesLang,
} = companyMessagesSlice.actions;
export default companyMessagesSlice.reducer;
