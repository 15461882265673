import { isPossiblePhoneNumber, parsePhoneNumber } from 'libphonenumber-js'

export const formatPhone = (phone?: string): string => {
    if (!phone) return ''
    const isPossible = isPossiblePhoneNumber('+' + phone)
    if (!isPossible) return phone

    const phoneNumber = parsePhoneNumber('+' + phone)

    return phoneNumber.formatInternational()
}
