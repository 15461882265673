import { GptType } from "@@types/gpt";
import {
  createGptApi,
  deleteGptApi,
  getAllGptApi,
  updateGptApi,
} from "@@services/GptServices";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

export type GptSlice = {
  data: GptType[];
};

const initialState: GptSlice = { data: [] };

export const getAllGpt = createAsyncThunk("gpt/getAllGpt", async () => {
  const response = await getAllGptApi();
  return response.data;
});

export const updateGpt = createAsyncThunk(
  "gpt/updateGpt",
  async (data: GptType) => {
    const response = await updateGptApi(data);
    return response.data;
  }
);

export const createGpt = createAsyncThunk(
  "gpt/createGpt",
  async (data: GptType) => {
    const response = await createGptApi(data);
    return response.data;
  }
);

export const deleteGpt = createAsyncThunk(
  "gpt/deleteGpt",
  async (data: GptType) => {
    await deleteGptApi(data);
    return data;
  }
);

export const gptSlice = createSlice({
  name: "gptSlice",
  initialState,
  reducers: {
    createGptData(state, action: PayloadAction<GptType>) {
      state.data.push(action.payload);
    },
    updateGptData(state, action: PayloadAction<GptType>) {
      const index = state.data.findIndex((gpt) => gpt.id === action.payload.id);
      state.data[index] = action.payload;
    },
    deleteGptData(state, action: PayloadAction<number>) {
      const index = state.data.findIndex((gpt) => gpt.id === action.payload);
      state.data.splice(index, 1);
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getAllGpt.fulfilled, (state, action) => {
        state.data = action.payload;
      })
      .addCase(createGpt.fulfilled, (state, action) => {
        state.data.push(action.payload);
      })
      .addCase(updateGpt.fulfilled, (state, action) => {
        const index = state.data.findIndex(
          (item) => item.id === action.payload.id
        );
        state.data[index] = action.payload;
      })
      .addCase(deleteGpt.fulfilled, (state, action) => {
        const index = state.data.findIndex(
          (item) => item.id === action.payload.id
        );
        state.data.splice(index, 1);
      });
  },
});

export const allGpts = (state: RootState) => state.gptSlice.data;

export const { createGptData, deleteGptData, updateGptData } = gptSlice.actions;

export default gptSlice.reducer;
