import { Appointment, Label } from "@@types/appointment";
import ApiService from "./ApiService";

export const getAllAppointment = async () => {
  return ApiService.fetchData<Array<Appointment>>({
    url: "/appointments",
    method: "GET",
  });
};
export const getAllLabels = async () => {
  return ApiService.fetchData<Array<Label>>({
    url: "/appointments/label",
    method: "GET",
  });
};

export const createLabelApi = async (data: Label) => {
  return ApiService.fetchData<Label>({
    url: "/appointments/label",
    method: "POST",
    data,
  });
};
export const updateLabelApi = async (data: Label) => {
  return ApiService.fetchData<Label>({
    url: `/appointments/label/${data.id}`,
    method: "PUT",
    data,
  });
};
export const deleteLabelApi = async (data: Label) => {
  return ApiService.fetchData<Label>({
    url: `/appointments/label/${data.id}`,
    method: "DELETE",
    data,
  });
};
export const getTodatAppointment = async () => {
  return ApiService.fetchData<Array<Appointment>>({
    url: "/appointments/today",
    method: "GET",
  });
};
export const createAppointment = async (data: Appointment) => {
  return ApiService.fetchData<Appointment>({
    url: "/appointments",
    method: "post",
    data,
  });
};

export const updateAppointmentService = async (data: Appointment) => {
  return ApiService.fetchData<Appointment>({
    url: `/appointments/${data.id}`,
    method: "put",
    data,
  });
};
export const cancelAppointmentService = async (id: number) => {
  return ApiService.fetchData<Appointment>({
    url: `/appointments/cancel/${id}`,
    method: "get",
  });
};
export const deleteAppointmentService = async (id: number) => {
  return ApiService.fetchData<Appointment>({
    url: `/appointments/${id}`,
    method: "delete",
  });
};
