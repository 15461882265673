import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ContactsFilter, contactsList, contactsTags } from "./contactsSlice";
import { read, utils, write } from "xlsx";
import { TableQueries } from "@@types/common";
import { Contact, Tag } from "@@types/contacts";
import { ContactsGroup } from "@@types/contactsGroup";
import {
  createContactsGroups,
  getContactsGroups,
} from "@@services/ContactsGroupsServices";
import { AppThunk, RootState } from "../store";
import { createExcelFile } from "@fuse/utils/createExcelFile";

export const initialTableData: TableQueries = {
  total: 0,
  pageIndex: 1,
  pageSize: 10,
  query: "",
  sort: {
    order: "",
    key: "",
  },
};

export type ContactsGroupsState = {
  loading: boolean;

  filterData: ContactsFilter;
  tableQueries: TableQueries;
  exceltableQueries: TableQueries;

  selectedContacts: Contact[];
  selectedContactsByTags: Contact[];
  tags: Tag[];
  file?: File;
  name: string;
  excelData: any[];
  excelKeys: string[];
  errors?: {
    name?: string;
    file?: string;
  };
  formPages: "form" | "excelData";
  contactsGroupsList: ContactsGroup[];
};

const initialState: ContactsGroupsState = {
  loading: false,
  filterData: {
    tags: [],
    search: "",
    page: 0,
    limit: 20,
    from: null,
    to: null,
  },
  selectedContacts: [],
  selectedContactsByTags: [],
  tags: [],
  tableQueries: initialTableData,
  exceltableQueries: initialTableData,
  name: "",
  excelData: [],
  excelKeys: [],
  errors: {
    name: "",
    file: "",
  },
  formPages: "form",
  contactsGroupsList: [],
};

export const createContactsGroup = createAsyncThunk(
  "contactsGroupsSlice/createContactsGroups",
  async (_: undefined, { getState, dispatch }) => {
    const name = groupName(getState() as any);
    const data = excelData(getState() as any);
    const file = await createExcelFile(data);
    dispatch(handleReset());
    const form = new FormData();
    form.append("name", name);
    form.append("file", file, "file.xlsx");
    const res = await createContactsGroups(form);
    return res.data;
  }
);
export const getContactsGroupsList = createAsyncThunk(
  "contactsGroupsSlice/getContactsGroupsList",
  async () => {
    const res = await getContactsGroups();
    return res.data;
  }
);

export const contactsGroupsSlice = createSlice({
  name: "contactsGroupsSlice",
  initialState,
  reducers: {
    setFilterData(state, action: PayloadAction<ContactsFilter>) {
      state.filterData = {
        ...state.filterData,
        ...action.payload,
      };
    },

    setExcelTableQueries(state, action) {
      state.exceltableQueries = action.payload;
    },
    addSelectedContacts(state, action) {
      state.selectedContacts = [...state.selectedContacts, action.payload];
    },
    addSelectedContactsByTag(state, action: PayloadAction<Contact[]>) {
      const list: Contact[] = [];
      action.payload.forEach((contact) => {
        if (!state.selectedContactsByTags.find((c) => c.id === contact.id)) {
          list.push(contact);
        }
      });
      state.selectedContactsByTags = [...list];
    },
    removeSelectedContactsByTags(state, action) {
      state.selectedContacts = state.selectedContacts.filter(
        (contact) => contact.id !== action.payload.id
      );
    },
    removeSelectedContacts(state, action) {
      state.selectedContacts = state.selectedContacts.filter(
        (contact) => contact.id !== action.payload.id
      );
      state.selectedContactsByTags = state.selectedContactsByTags.filter(
        (contact) => contact.id !== action.payload.id
      );
    },
    addTags(state, action) {
      state.tags = [...action.payload];
    },
    addFile(state, action) {
      state.file = action.payload;
    },
    setName(state, action) {
      state.name = action.payload;
    },
    setExcelData(state, action) {
      state.excelData = action.payload;
    },
    setExcelKeys(state, action) {
      state.excelKeys = action.payload;
    },
    handleNewColumn(state, action: PayloadAction<string>) {
      state.excelKeys.push(action.payload);
      state.excelData = state.excelData.map((data) => ({
        ...data,
        [action.payload]: "",
      }));
    },
    updateExcel(
      state,
      action: PayloadAction<{
        index: number;
        key: string;
        value: string;
      }>
    ) {
      const index = action.payload.index;
      const key = action.payload.key;
      const value = action.payload.value;
      state.excelData[index][key] = value;
    },
    setError(state, action) {
      state.errors = { ...state.errors, ...action.payload };
    },
    setPage(state, action: PayloadAction<"form" | "excelData">) {
      state.formPages = action.payload;
    },
    handleReset(state) {
      return {
        // ...state,
        ...initialState,
        contactsGroupsList: state.contactsGroupsList,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getContactsGroupsList.fulfilled, (state, action) => {
        state.contactsGroupsList = action.payload;
      })
      .addCase(createContactsGroup.fulfilled, (state, action) => {
        state.contactsGroupsList = [
          ...state.contactsGroupsList,
          action.payload,
        ];
      });
  },
});

export const filterData = (state: RootState) => {
  return state.contactsGroupsSlice.filterData;
};

export const tableQueries = (state: RootState) => {
  return state.contactsGroupsSlice.tableQueries;
};
export const selectedTags = (state: RootState) => {
  return state.contactsGroupsSlice.tags;
};
export const selectedFile = (state: RootState) => {
  return state.contactsGroupsSlice.file;
};
export const groupName = (state: RootState) => {
  return state.contactsGroupsSlice.name;
};
export const excelData = (state: RootState) => {
  return state.contactsGroupsSlice.excelData;
};
export const exceltableQueries = (state: RootState) => {
  return state.contactsGroupsSlice.exceltableQueries;
};

export const selectedContacts = (state: RootState) => {
  return state.contactsGroupsSlice.selectedContacts;
};

export const selectedContactsByTags = (state: RootState) => {
  return state.contactsGroupsSlice.selectedContactsByTags;
};

export const contactsGroupsErrors = (state: RootState) => {
  return state.contactsGroupsSlice.errors;
};
export const contactsGroupsPage = (state: RootState) => {
  return state.contactsGroupsSlice.formPages;
};
export const contactsGroupsExcelKeys = (state: RootState) => {
  return state.contactsGroupsSlice.excelKeys;
};
export const contactsGroupsList = (state: RootState) => {
  return state.contactsGroupsSlice.contactsGroupsList;
};

export const isSelectedContact = (state: RootState, contact: Contact) =>
  !!state.contactsGroupsSlice.selectedContacts.find(
    (c) => c.id === contact.id
  ) ||
  !!state.contactsGroupsSlice.selectedContactsByTags.find(
    (c) => c.id === contact.id
  );

export const addSelectedTags =
  (tags: Tag[]): AppThunk =>
  (dispatch, getState) => {
    const contacts = contactsList(getState());

    // tags.forEach((tag) => {
    // })
    dispatch(
      addSelectedContactsByTag(
        tags.length
          ? contacts.filter(
              (contact) =>
                !!contact.tags?.some(
                  (tag) => !tags.some((t) => t.id === tag.id)
                )
            )
          : []
      )
    );
    dispatch(addTags(tags));
  };
const readExcelFile = (file: File) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);

    fileReader.onload = (e) => {
      const bufferArray = e.target?.result;
      const wb = read(bufferArray, { type: "buffer" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = utils.sheet_to_json(ws);
      resolve(data);
    };

    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

export const handleNextBtn = (): AppThunk => async (dispatch, getState) => {
  try {
    const name = groupName(getState());
    const file = selectedFile(getState());
    const contacts = selectedContacts(getState());
    const contactsByTags = selectedContactsByTags(getState());
    const allSelectedContacts: Contact[] = [];

    let excelKeys: string[] = [];
    contacts.forEach((contact) => {
      if (contactsByTags.findIndex((c) => c.waId === contact.waId) === -1) {
        allSelectedContacts.push(contact);
      }
    });

    let excelData = [];

    if (!name) {
      dispatch(
        setError({
          name: "contactsGroups.nameError",
        })
      );
      return;
    } else {
      dispatch(
        setError({
          name: "",
        })
      );
    }

    if (file) {
      excelData = (await readExcelFile(file)) as any[];

      if (excelData?.length === 0) return;
      excelKeys = [
        ...new Set(
          excelData
            .map((d: any) => Object.keys(d).map((key) => key.trim()))
            .flatMap((keys) => keys)
        ),
      ];
      excelData = excelData.map((data: any) => {
        const keys = Object.keys(data);
        const newData: any = {};
        keys.forEach((k) => {
          newData[k.trim()] = data[k];
        });
        if (newData.phone) {
          newData.phone = String(newData.phone)
            ?.replaceAll("+", "")
            ?.replaceAll(" ", "")
            ?.trim();
        }
        return newData;
      });

      if (!excelKeys.includes("phone")) {
        dispatch(
          setError({
            file: "contactsGroups.fileError",
          })
        );
        return;
      } else if (excelData.some((d) => !d.phone)) {
        dispatch(
          setError({
            file: "contactsGroups.phoneEmpty",
          })
        );
        return;
      } else {
        dispatch(
          setError({
            file: "",
          })
        );
      }
    } else {
      excelKeys = ["name", "phone"];
    }
    dispatch(setExcelKeys(excelKeys));

    const excelDataWithoutDuplicated: any[] = [];

    excelData.forEach((row: any) => {
      if (
        excelDataWithoutDuplicated.findIndex((r) => r.phone === row.phone) ===
        -1
      ) {
        excelDataWithoutDuplicated.push(row);
      }
    });

    const hasNameField = excelKeys.includes("name");

    allSelectedContacts.forEach((contact) => {
      if (
        excelDataWithoutDuplicated.findIndex(
          (row) => row.phone === contact.waId
        ) === -1
      ) {
        const fileds: any = {};
        excelKeys.forEach((key) => (fileds[key] = ""));
        excelDataWithoutDuplicated.unshift(
          hasNameField
            ? {
                ...fileds,
                name: contact.nickname,
                phone: contact.waId,
              }
            : {
                ...fileds,

                phone: contact.waId,
              }
        );
      }
    });
    dispatch(setExcelData(excelDataWithoutDuplicated));
    dispatch(setPage("excelData"));
  } catch (error) {
    console.log(error);
  }
};

export const {
  setFilterData,
  addSelectedContacts,
  removeSelectedContacts,
  addTags,
  addSelectedContactsByTag,
  addFile,
  setName,
  setExcelTableQueries,
  updateExcel,
  setError,
  setExcelData,
  setPage,
  setExcelKeys,
  handleReset,
  handleNewColumn,
} = contactsGroupsSlice.actions;

export default contactsGroupsSlice.reducer;
