import { SettingsData } from "@@app/store/slices/settings";
import ApiService from "./ApiService";

export const getSettingsApi = () => {
  return ApiService.fetchData<SettingsData[]>({
    url: "/settings",
    method: "get",
  });
};
export const updateSettingsApi = (data: SettingsData) => {
  return ApiService.fetchData<SettingsData>({
    url: "/settings",
    method: "post",
    data,
  });
};
export const getConfigApi = () => {
  return ApiService.fetchData({
    url: "/settings/config",
    method: "get",
  });
};
