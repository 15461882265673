import { useAppSelector } from "@@app/store/hooks";
import { configData } from "@@app/store/slices/settings";
import { styled } from "@mui/material/styles";
import { useMemo } from "react";

const Root = styled("div")(({ theme }) => ({
  "& > .logo-icon": {
    transition: theme.transitions.create(["width", "height"], {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut,
    }),
  },
  "& > .badge": {
    transition: theme.transitions.create("opacity", {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut,
    }),
  },
}));

/**
 * The logo component.
 */
function Logo() {
  const config = useAppSelector(configData);

  const logo = useMemo(() => {
    if (config.product === "rasael") {
      return "assets/images/logo/rasael-logo-white.png";
    } else if (config.product === "whatsbot") {
      return "assets/images/logo/whatsbot-logo-white.png";
    } else if (config.product === "rasael-pro") {
      return "assets/images/logo/rasaelpro-logo-white.png";
    }

    return "";
  }, [config]);
  return (
    <Root className="flex items-center">
      <img
        width={150}
        height={42}
        className="logo-icon h-full mt-3"
        src={logo}
        alt="logo"
      />
    </Root>
  );
}

export default Logo;
