import { styled } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import { ConversationType } from "@@types/chat";

const StyledBadge = styled("div")({
  position: "relative", // Ensure relative positioning for proper badge placement
  display: "inline-block",
  width: "fit-content",
});

const StyledStatus = styled("div")<{
  color: string;
  left: number;
  top: number;
}>(({ theme, color, left, top }) => ({
  position: "absolute",
  width: 12,
  height: 12,
  left: `${left}px`,
  top: `${top}px`,
  border: `2px solid ${theme.palette.background.default}`,
  borderRadius: "50%",
  zIndex: 10, // Ensure badges are on top of the avatar
  backgroundColor: color,
}));

type UserAvatarPropsType = {
  name: string;
  className?: string;
  bg?: string;
  conversationsTypes?: ConversationType[];
};

/**
 * The user avatar component.
 */
function UserAvatar(props: UserAvatarPropsType) {
  const { name, className, bg, conversationsTypes } = props;
  const avatarSize = 40; // Avatar diameter in px
  const badgeSize = 12; // Badge size in px
  const badgeRadius = avatarSize / 2; // Adjust radius to the edge of the avatar
  const centerX = avatarSize / 2; // Center X of the avatar
  const centerY = avatarSize / 2; // Center Y of the avatar
  const maxBadges = 8; // Max number of badges before overlapping
  const badges = conversationsTypes ?? [];

  // Calculate how many badges to display and if they should overlap
  const badgesToDisplay = badges.length > maxBadges ? maxBadges : badges.length;

  // Shift the starting angle to π/4 (45 degrees) to begin at the bottom-right
  const startingAngle = Math.PI / 4;

  const badgePositions = badges
    .slice(0, badgesToDisplay)
    .map((badge, index) => {
      // Calculate the angle for each badge, rotating clockwise
      const angle = startingAngle + (index / badgesToDisplay) * (2 * Math.PI);

      // Calculate the x and y position based on the angle
      const x = centerX + badgeRadius * Math.cos(angle) - badgeSize / 2; // Position exactly on the edge
      const y = centerY + badgeRadius * Math.sin(angle) - badgeSize / 2; // Position exactly on the edge

      return <StyledStatus key={index} color={badge.color} left={x} top={y} />;
    });

  return (
    <StyledBadge className={className}>
      <Avatar
        src={""}
        alt={name}
        className={`w-full h-full !text-white ${bg ? bg : ""}`}
        style={{
          backgroundColor: bg,
          width: avatarSize,
          height: avatarSize,
        }}
      >
        {name ? name[0]?.toUpperCase() : ""}
      </Avatar>
      {badgePositions}
    </StyledBadge>
  );
}

export default UserAvatar;
