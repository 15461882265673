import { memo, useMemo } from "react";
import Box from "@mui/material/Box";
import { useAppSelector } from "@@app/store/hooks";
import { configData } from "@@app/store/slices/settings";

/**
 * The FuseSplashScreen component is responsible for rendering a splash screen with a logo and a loading spinner.
 * It uses various MUI components to render the logo and spinner.
 * The component is memoized to prevent unnecessary re-renders.
 */
function FuseSplashScreen() {
  const config = useAppSelector(configData);

  const logo = useMemo(() => {
    if (config.product === "rasael") {
      return "assets/images/logo/rasael-logo-black.png";
    } else if (config.product === "whatsbot") {
      return "assets/images/logo/whatsbot-logo-black.png";
    } else if (config.product === "rasael-pro") {
      return "assets/images/logo/rasaelpro-logo-black.png";
    }

    return "";
  }, [config]);

  return (
    <div id="fuse-splash-screen">
      <div className="logo">
        <img
          width={250}
          style={{ width: 250, maxWidth: 250 }}
          src={logo}
          alt="logo"
        />
      </div>
      <Box
        id="spinner"
        sx={{
          "& > div": {
            backgroundColor: "palette.secondary.main",
          },
        }}
      >
        <div className="bounce1" />
        <div className="bounce2" />
        <div className="bounce3" />
      </Box>
    </div>
  );
}

export default memo(FuseSplashScreen);
