import { UsersChat, TeamMessage } from "@@types/teamChat";
import ApiService from "./ApiService";

export const getTeamChatsApi = () => {
  return ApiService.fetchData<UsersChat[]>({
    url: "/team-messages/users",
    method: "get",
  });
};
export const getTeamMessagesApi = (id: number, lastMsgDate?: string) => {
  return ApiService.fetchData<TeamMessage[]>({
    url: `/team-messages/${id}?lastMsgDate=${lastMsgDate ?? ""}`,
    method: "get",
  });
};
export const readTeamMessagesApi = (id: number) => {
  return ApiService.fetchData<TeamMessage[]>({
    url: `/team-messages/${id}/read`,
    method: "get",
  });
};
