import { Contact } from "@@types/contacts";
import ApiService from "./ApiService";
import { ContactsFilter } from "@@app/store/slices/contactsSlice";

export async function apiGetCrmDashboardData<T>() {
  return ApiService.fetchData<T>({
    url: "/crm/dashboard",
    method: "get",
  });
}

export async function apiGetCrmCalendar<T>() {
  return ApiService.fetchData<T>({
    url: "/crm/calendar",
    method: "get",
  });
}

export async function apiGetContacts<T>({
  from,
  page,
  search,
  tags,
  to,
}: ContactsFilter) {
  const searchParams = new URLSearchParams();
  if (search)
    searchParams.append(
      "search",
      search.replaceAll(" ", "").replaceAll("+", "")
    );

  if (from) searchParams.append("dateFrom", from);
  if (to) searchParams.append("dateTo", to);
  if (tags?.length) searchParams.append("tags", tags.join(","));
  searchParams.append("limit", "20");
  searchParams.append("page", page?.toString() ?? "1");
  return ApiService.fetchData<T>({
    url: `/contacts?${searchParams.toString()}`,
    method: "get",
  });
}
export async function apiPutContact<T>(data: Contact) {
  return ApiService.fetchData<T>({
    url: `/contacts/${data.id}`,
    method: "put",
    data,
  });
}
export async function apiCreateContact<T>(data: Contact) {
  return ApiService.fetchData<T>({
    url: `/contacts`,
    method: "post",
    data,
  });
}

export async function apiGetCrmCustomersStatistic<T>() {
  return ApiService.fetchData<T>({
    url: "/contacts/statistics",
    method: "get",
  });
}

export async function apiGetTags<T>() {
  return ApiService.fetchData<T>({
    url: "/tags",
    method: "get",
  });
}
export async function apiCreateTags<T>(data: any) {
  return ApiService.fetchData<T>({
    url: "/tags",
    method: "post",
    data,
  });
}
export async function apiUpdateTags<T>(data: any) {
  return ApiService.fetchData<T>({
    url: `/tags/${data.id}`,
    method: "put",
    data,
  });
}
export async function apiDeleteTags<T>(data: any) {
  return ApiService.fetchData<T>({
    url: `/tags/${data.id}`,
    method: "delete",
  });
}
