import {
  getCompanyMsgsMasterCharts,
  getCompanyMsgsMasterChartsData,
  getCompanyMsgsMasterSendingChartsData,
} from "@@services/CompanyMessageService";
import {
  apiAddKeywords,
  apiDeleteKeywords,
  apiGetContactsInDate,
  apiGetContactsLastHalfMonth,
  apiGetCrmCustomersStatisticCountries,
  apiGetKeywords,
  apiGetMsgsInDate,
  apiGetRabshConversations,
  apiUpdateKeywords,
} from "@@services/DashboardService";
import { CompanyMessages } from "@@types/companyMessages";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { getRandomColor } from "@fuse/utils/generateRandomColor";

export type Statistic = {
  iconKey: string;
  label?: string;
  value?: number;
  growShrink?: number;
};
export type StatisticData = { [key: string]: number | undefined };

export type LeadRegion = {
  name: string;
  color: string;
  value: number;
};

export type Lead = {
  id: number;
  name: string;
  avatar: string;
  status: number;
  createdTime: number;
  email: string;
  assignee: string;
};

export type Emails = {
  precent: number;
  opened: number;
  unopen: number;
  total: number;
};
export type ContactsByDateType = {
  date: string;
  conversations: number;
  answeredConversations: number;
  conversationsBot: number;
  total: number;
  unAnsweredConversations: number;
};
export type MsgsInDateType = {
  hour: number;
  msgs: number;
};
export type ContactsInDateType = {
  hour: number;
  conversation: number;
};

export type CompantMsgsChart = {
  masterId: number;
  masterName: string;
  data: Array<{ title: string; count: number }>;
  notSelected: number;
};

export type SendingMsgs = {
  title: string;
  count: number;
};
export type Keywords = {
  id: number;
  keyword: string;
};

export type DashboardData = {
  statisticData: StatisticData;
  leadByRegionData: LeadRegion[];
  recentLeadsData: Lead[];
  contactsByDate: ContactsByDateType[];
  msgsInDate: MsgsInDateType[];
  contactsInDate: ContactsInDateType[];
  companyMsgs: { loading: boolean; data: Array<CompanyMessages> };
  companyMsgsCharts: Array<CompantMsgsChart>;
  SendingMsgsChart: Array<SendingMsgs>;
  keywords: {
    loading: boolean;
    data: Array<Keywords>;
  };
  rabshConversations: { realConversations: number; rabshConversations: number };

  emailSentData: {
    precent: number;
    opened: number;
    unopen: number;
    total: number;
  };
};

export type CrmDashboardState = {
  loading: boolean;
  dashboardData: DashboardData;
};

export const SLICE_NAME = "crmDashboard";

export const getCrmCountriesData = createAsyncThunk(
  "dashboard/data/getCrmCountriesData",
  async () => {
    const response = await apiGetCrmCustomersStatisticCountries();
    return response.data;
  }
);
export const getContactsByDate = createAsyncThunk(
  "dashboard/data/getContactsByDate",
  async (data: { startDate: string; endDate: string }) => {
    const response =
      await apiGetContactsLastHalfMonth<ContactsByDateType[]>(data);
    return response.data;
  }
);
export const getGetMsgsInDate = createAsyncThunk(
  "dashboard/data/GetMsgsInDate",
  async ({ start, end }: { start?: string; end?: string }) => {
    const response = await apiGetMsgsInDate<MsgsInDateType[]>(start, end);
    return response.data;
  }
);
export const getGetContactsInDate = createAsyncThunk(
  "dashboard/data/GetContactsInDate",
  async ({ start, end }: { start?: string; end?: string }) => {
    const response = await apiGetContactsInDate<ContactsInDateType[]>(
      start,
      end
    );
    return response.data;
  }
);
export const getRabshConversations = createAsyncThunk(
  "dashboard/data/getRabshConversations",
  async ({
    start,
    end,
    messagesCount,
  }: {
    messagesCount: number;
    start?: string;
    end?: string;
  }) => {
    const response = await apiGetRabshConversations<{
      realConversations: number;
      rabshConversations: number;
    }>(messagesCount, start, end);
    return response.data;
  }
);

export const companyMsgsMasterCharts = createAsyncThunk(
  "dashboard/data/companyMsgsMasterCharts",
  async () => {
    const response = await getCompanyMsgsMasterCharts();
    return response.data;
  }
);
export const companyMsgsMasterChartsData = createAsyncThunk(
  "dashboard/data/companyMsgsMasterChartsData",
  async (data: { id: number; startDate: string; endDate: string }) => {
    const response = await getCompanyMsgsMasterChartsData(data);
    return response.data;
  }
);
export const companyMsgsMasterSendingChartsData = createAsyncThunk(
  "dashboard/data/companyMsgsMasterSendingChartsData",
  async (data: { startDate: string; endDate: string }) => {
    const response = await getCompanyMsgsMasterSendingChartsData(data);
    return response.data;
  }
);
export const getKeywords = createAsyncThunk(
  "dashboard/data/getKeywords",
  async () => {
    const response = await apiGetKeywords<Array<Keywords>>();
    return response.data;
  }
);
export const deleteKeywords = createAsyncThunk(
  "dashboard/data/deleteKeywords",
  async (id: number) => {
    const response = await apiDeleteKeywords(id);
    return id;
  }
);
export const updateKeywords = createAsyncThunk(
  "dashboard/data/updateKeywords",
  async ({ id, keyword }: { id: number; keyword: string }) => {
    const response = await apiUpdateKeywords<Keywords>(id, keyword);
    return response.data;
  }
);
export const addKeywords = createAsyncThunk(
  "dashboard/data/addKeywords",
  async (keyword: string) => {
    const response = await apiAddKeywords<Keywords>(keyword);
    return response.data;
  }
);

const initialState: CrmDashboardState = {
  loading: false,
  dashboardData: {
    statisticData: {},
    emailSentData: {
      precent: 97,
      total: 461,
      opened: 1,
      unopen: 1,
    },
    contactsByDate: [],
    msgsInDate: [],
    companyMsgs: { loading: false, data: [] },
    contactsInDate: [],
    companyMsgsCharts: [],
    leadByRegionData: [],
    recentLeadsData: [],
    SendingMsgsChart: [],
    rabshConversations: {
      rabshConversations: 0,
      realConversations: 0,
    },
    keywords: {
      data: [],
      loading: false,
    },
  },
};

export const dashboardSlice = createSlice({
  name: `dashboardSlice`,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCrmCountriesData.fulfilled, (state, action: any) => {
        const data = Object.entries(action.payload.countriesData as any).map(
          ([key, value]) => ({
            name: key,
            value,
            color: getRandomColor(),
          })
        ) as LeadRegion[];
        state.dashboardData.leadByRegionData = data;
        state.dashboardData.statisticData! = {
          contacts: action.payload.contactsCount,
          messages: action.payload.msgsCount,
          appointment: action.payload.appointmentCount,
          templates: action.payload.templates,
        };
        state.loading = false;
      })
      .addCase(getCrmCountriesData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getContactsByDate.fulfilled, (state, action) => {
        state.dashboardData.contactsByDate = action.payload;
      })
      .addCase(getGetMsgsInDate.fulfilled, (state, action) => {
        state.dashboardData.msgsInDate = action.payload;
      })
      .addCase(getGetContactsInDate.fulfilled, (state, action) => {
        state.dashboardData.contactsInDate = action.payload;
      })
      .addCase(companyMsgsMasterCharts.pending, (state) => {
        state.dashboardData.companyMsgs = {
          loading: true,
          data: [],
        };
      })
      .addCase(companyMsgsMasterCharts.fulfilled, (state, action) => {
        state.dashboardData.companyMsgs = {
          loading: true,
          data: action.payload,
        };
      })

      .addCase(companyMsgsMasterChartsData.fulfilled, (state, action) => {
        const id = action.payload.masterId;
        const index = state.dashboardData.companyMsgsCharts?.findIndex(
          (master) => master.masterId === id
        );
        if (index !== -1) {
          state.dashboardData.companyMsgsCharts[index] = action.payload;
        } else {
          state.dashboardData.companyMsgsCharts.push(action.payload);
        }
      })
      .addCase(getRabshConversations.fulfilled, (state, action) => {
        state.dashboardData.rabshConversations = action.payload;
      })
      .addCase(getKeywords.pending, (state, action) => {
        state.dashboardData.keywords.loading = true;
      })
      .addCase(getKeywords.fulfilled, (state, action) => {
        state.dashboardData.keywords.loading = false;
        state.dashboardData.keywords.data = action.payload;
      })
      .addCase(deleteKeywords.fulfilled, (state, action) => {
        state.dashboardData.keywords.data =
          state.dashboardData.keywords.data.filter(
            (keyword) => keyword.id !== action.payload
          );
      })
      .addCase(addKeywords.fulfilled, (state, action) => {
        state.dashboardData.keywords.data.push(action.payload);
      })
      .addCase(updateKeywords.fulfilled, (state, action) => {
        const index = state.dashboardData.keywords.data.findIndex(
          (k) => k.id === action.payload.id
        );
        if (index !== -1) {
          state.dashboardData.keywords.data[index] = action.payload;
        }
      })
      .addCase(
        companyMsgsMasterSendingChartsData.fulfilled,
        (state, action) => {
          state.dashboardData.SendingMsgsChart = action.payload;
        }
      );
  },
});

export const contactByDate = (state: RootState) =>
  state.dashboardSlice.dashboardData.contactsByDate;
export const msgsInDate = (state: RootState) =>
  state.dashboardSlice.dashboardData.msgsInDate;
export const contactsInDate = (state: RootState) =>
  state.dashboardSlice.dashboardData.contactsInDate;

export const compantMsgsCharts = (state: RootState) =>
  state.dashboardSlice.dashboardData.companyMsgs?.data;

export const compantMsgsSendingCharts = (state: RootState) =>
  state.dashboardSlice.dashboardData.SendingMsgsChart;

export const rabshConversations = (state: RootState) =>
  state.dashboardSlice.dashboardData.rabshConversations;

export const keywords = (state: RootState) =>
  state.dashboardSlice.dashboardData.keywords;

export const companyMsgsChartsData = (state: RootState, id: number) =>
  state.dashboardSlice.dashboardData.companyMsgsCharts.find(
    (data) => data.masterId === id
  );

export default dashboardSlice.reducer;
