import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import AvatarGroup from "@mui/material/AvatarGroup";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import CardContent from "@mui/material/CardContent";
import _ from "@lodash";
import Alert from "@mui/material/Alert";
import JwtLoginTab from "./tabs/JwtSignInTab";
import FirebaseSignInTab from "./tabs/FirebaseSignInTab";
import AwsSignInTab from "./tabs/AwsSignInTab";
import { useAppSelector } from "@@app/store/hooks";
import { selectUser } from "@@app/store/slices/userSlice";
import RedirctToAuthPage from "@fuse/utils/RedirctToAuthPage";

const tabs = [
  {
    id: "jwt",
    title: "JWT",
    logo: "assets/images/logo/jwt.svg",
    logoClass: "h-40 p-4 bg-black rounded-12",
  },
  {
    id: "firebase",
    title: "Firebase",
    logo: "assets/images/logo/firebase.svg",
    logoClass: "h-40",
  },
  {
    id: "aws",
    title: "AWS",
    logo: "assets/images/logo/aws-amplify.svg",
    logoClass: "h-40",
  },
];

/**
 * The sign in page.
 */
function SignInPage() {
  const [selectedTabId, setSelectedTabId] = useState(tabs[0].id);
  const user = useAppSelector(selectUser);

  function handleSelectTab(id: string) {
    setSelectedTabId(id);
  }

  if (user) return <RedirctToAuthPage />;

  return (
    <div className="flex min-w-0 flex-1 flex-col items-center sm:flex-row sm:justify-center md:items-start md:justify-start">
      <Paper className="h-full w-full px-16 py-8 ltr:border-r-1 rtl:border-l-1 sm:h-auto sm:w-auto sm:rounded-2xl sm:p-48 sm:shadow md:flex md:h-full md:w-1/2 md:items-center  md:rounded-none md:p-64 md:shadow-none">
        <CardContent className="mx-auto w-full max-w-320 sm:mx-0 sm:w-320">
          <img
            width={200}
            src="assets/images/logo/logo.png"
            alt="logo"
            className="mb-72"
          />

          <Typography className="mt-32 text-4xl font-extrabold leading-tight tracking-tight">
            Sign in
          </Typography>

          {/* <Alert
            icon={false}
            severity="info"
            className="mt-24 px-16 text-13 leading-relaxed"
          >
            You are browsing <b>Fuse React Demo</b>. Click on the "Sign in"
            button to access the Demo and Documentation.
          </Alert> */}

          <JwtLoginTab />
        </CardContent>
      </Paper>

      <Box
        className="relative hidden h-full flex-auto items-center justify-center overflow-hidden p-64 md:flex lg:px-112"
        sx={{ backgroundColor: "primary.main" }}
      >
        <svg
          className="pointer-events-none absolute inset-0"
          viewBox="0 0 960 540"
          width="100%"
          height="100%"
          preserveAspectRatio="xMidYMax slice"
          xmlns="http://www.w3.org/2000/svg"
        >
          <Box
            component="g"
            sx={{ color: "primary.light" }}
            className="opacity-20"
            fill="none"
            stroke="currentColor"
            strokeWidth="100"
          >
            <circle r="234" cx="196" cy="23" />
            <circle r="234" cx="790" cy="491" />
          </Box>
        </svg>
        <Box
          component="svg"
          className="absolute -right-64 -top-64 opacity-20"
          sx={{ color: "primary.light" }}
          viewBox="0 0 220 192"
          width="220px"
          height="192px"
          fill="none"
        >
          <defs>
            <pattern
              id="837c3e70-6c3a-44e6-8854-cc48c737b659"
              x="0"
              y="0"
              width="20"
              height="20"
              patternUnits="userSpaceOnUse"
            >
              <rect x="0" y="0" width="4" height="4" fill="currentColor" />
            </pattern>
          </defs>
          <rect
            width="220"
            height="192"
            fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)"
          />
        </Box>

        <div className="relative z-10 w-full max-w-2xl">
          <div className="text-7xl font-bold leading-none text-gray-100">
            <div>Welcome to Rasael AI</div>
            {/* <div>Rasael AI</div> */}
          </div>
          <div className="mt-24 text-lg leading-6 tracking-tight text-gray-400">
            <ul className="list-disc">
              <li>
                <b>Dynamic Dashboard:</b> Stay updated with interactive charts.
              </li>
              <li>
                <b>Seamless Interaction:</b> Use Flow Message via WhatsApp API.
              </li>
              <li>
                <b>User-Friendly:</b> Simplifies ChatGPT usage.
              </li>
              <li>
                <b>Mass Messaging:</b> Advanced scheduling and tracking.
              </li>
              <li>
                <b>High Security:</b> Ensure compliance and trust.
              </li>
              <li>
                <b>Customizable Campaigns:</b> Link ads with messages and bots.
              </li>
              <li>
                <b>Customer Feedback:</b> Enhance satisfaction through surveys.
              </li>
              <li>
                <b>Template Management:</b> Easily create and manage templates.
              </li>
              <li>
                <b>Continuous Updates:</b> Access the latest features.
              </li>
              <li>
                <b>Affordable Quality:</b> Top-notch service at competitive
                prices.
              </li>
            </ul>
          </div>
          <div className="mt-32 flex items-center">
            <AvatarGroup
              sx={{
                "& .MuiAvatar-root": {
                  borderColor: "primary.main",
                },
              }}
            >
              <Avatar src="assets/images/avatars/female-18.jpg" />
              <Avatar src="assets/images/avatars/female-11.jpg" />
              <Avatar src="assets/images/avatars/male-09.jpg" />
              <Avatar src="assets/images/avatars/male-16.jpg" />
            </AvatarGroup>

            {/* <div className="ml-16 font-medium tracking-tight text-gray-400">
              More than 17k people joined us, it's your turn
            </div> */}
          </div>
        </div>
      </Box>
    </div>
  );
}

export default SignInPage;
