import { CompanyMessages } from "@@types/companyMessages";
import ApiService from "./ApiService";
import { CompanyMessagesMaster } from "@@types/companyMessagesMaster";
import { CompantMsgsChart, SendingMsgs } from "@@app/store/slices/dashboard";

export const getCompanyMsgsMaster = async () => {
  return ApiService.fetchData<Array<CompanyMessagesMaster>>({
    url: `/messages-company/masters`,
    method: "get",
  });
};

export const getCompanyMsgsMasterCharts = async () => {
  return ApiService.fetchData<Array<CompanyMessages>>({
    url: `/messages-company/chart`,
    method: "get",
  });
};
export const getCompanyMsgsMasterChartsData = async (data: {
  id: number;
  startDate: string;
  endDate: string;
}) => {
  return ApiService.fetchData<CompantMsgsChart>({
    url: `/messages-company/chart/${data.id}?dateFrom=${data.startDate}&dateTo=${data.endDate}`,
    method: "get",
  });
};
export const getCompanyMsgsMasterSendingChartsData = async (data: {
  startDate: string;
  endDate: string;
}) => {
  return ApiService.fetchData<Array<SendingMsgs>>({
    url: `/messages-company/sending-chart/?dateFrom=${data.startDate}&dateTo=${data.endDate}`,
    method: "get",
  });
};

export const createCompanyMasterMsgs = async (data: any) => {
  return ApiService.fetchData({
    url: `/messages-company/master`,
    method: "post",
    data: data,
  });
};
export const createCompanyMsgs = async (data: any) => {
  const form = new FormData();
  const newData = Object.entries(data);
  newData.forEach(([key, value]) => {
    form.append(key, value as any);
  });

  return ApiService.fetchData({
    url: `/messages-company/lang`,
    method: "post",
    data: form,
    headers: { "Content-Type": "multipart/form-data" },
  });
};
export const updateCompanyMsgsApi = async (data: any) => {
  return ApiService.fetchData({
    url: `/messages-company/master/${data.id}`,
    method: "put",
    data: data,
  });
};

export const deleteCompanyMsgsMasterApi = async (id: number) => {
  return ApiService.fetchData({
    url: `/messages-company/masters/${id}`,
    method: "delete",
  });
};
export const deleteCompanyMsgsLangApi = async (id: number) => {
  return ApiService.fetchData({
    url: `/messages-company/lang/${id}`,
    method: "delete",
  });
};
