import { Role } from "@@types/roles";
import { lazy } from "react";

const SettingsApp = lazy(() => import("./SettingsApp"));

/**
 * The chat app config.
 */
const SettingsAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "settings",
      element: <SettingsApp />,
      auth: [Role.ADMIN],
    },
  ],
};

export default SettingsAppConfig;
