import { availableLanguages } from "@@locales/i18n";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

const useCurrentLang = () => {
  const { i18n } = useTranslation();

  const currentLang = useMemo(() => {
    const currLang: string = i18n.language.split("-")[0].toLowerCase();
    let lang;

    if (availableLanguages.includes(currLang)) {
      lang = currLang;
    } else {
      lang = "en";
    }

    return lang;
  }, [i18n.language]);

  return currentLang;
};

export default useCurrentLang;
