import { Message } from '@/@types/message'
import ApiService from './ApiService'

export const getMsgsFiltersService = async (
    page: number,
    limit: number,
    dateFrom?: string,
    dateTo?: string,
    sort?: string,
    search?: string,
) => {
    return ApiService.fetchData<{ messages: Array<Message>; count: number }>({
        url: `/messages/all/?limit=${limit}&page=${page}&dateFrom=${dateFrom}&dateTo=${dateTo}&sort=${sort}&search=${search}`,
        method: 'get',
    })
}
