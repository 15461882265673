import { Role } from "@@types/roles";
import { lazy } from "react";

const Text = lazy(() => import("./pages/text/TextMessages"));
const TextDetails = lazy(() => import("./ui/MessageDetails"));
const Contact = lazy(() => import("./pages/contact/ContactMessages"));
const Image = lazy(() => import("./pages/image/ImageMessages"));
const Video = lazy(() => import("./pages/video/VideoMessages"));
const Document = lazy(() => import("./pages/document/DocumentMessages"));
const Location = lazy(() => import("./pages/location/LoactionMessages"));
const Audio = lazy(() => import("./pages/audio/AudioMessages"));
const Interactive = lazy(
  () => import("./pages/interactive/InteractiveMessages")
);
const Template = lazy(() => import("./pages/template/TemplateMessages"));

/**
 * The chat app config.
 */
const CompanyMessagesAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "companyMessages/text",
      element: <Text />,
      auth: [Role.MESSAGES_COMPANY],
    },
    {
      path: "companyMessages/audio",
      element: <Audio />,
      auth: [Role.MESSAGES_COMPANY],
    },

    {
      path: "companyMessages/contact",
      element: <Contact />,
      auth: [Role.MESSAGES_COMPANY],
    },
    {
      path: "companyMessages/image",
      element: <Image />,
      auth: [Role.MESSAGES_COMPANY],
    },
    {
      path: "companyMessages/video",
      element: <Video />,
      auth: [Role.MESSAGES_COMPANY],
    },
    {
      path: "companyMessages/document",
      element: <Document />,
      auth: [Role.MESSAGES_COMPANY],
    },
    {
      path: "companyMessages/location",
      element: <Location />,
      auth: [Role.MESSAGES_COMPANY],
    },
    {
      path: "companyMessages/interactive",
      element: <Interactive />,
      auth: [Role.MESSAGES_COMPANY],
    },
    {
      path: "companyMessages/template",
      element: <Template />,
      auth: [Role.MESSAGES_COMPANY],
    },
  ],
};

export default CompanyMessagesAppConfig;
