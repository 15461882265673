import { getBotMsgs, getFunctions } from "@@services/Bot";
import { MsgBot } from "@@types/msgBot";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "../store";
import { socket } from "@@socket/webSocket";

type Line = { from: string; to: string };

export type BotSliceState = {
  previewImage: string;
  drawLineFrom?: string;
  lines: Array<Line>;
  msgsBot: Array<MsgBot>;
  functions: Array<string>;
  componentDrag: boolean;
};

const initialState: BotSliceState = {
  previewImage: "",
  lines: [],
  msgsBot: [],
  functions: [],
  componentDrag: false,
};
export const getAllBotMsgs = createAsyncThunk(
  "botSlice/createNewAppointment",
  async () => {
    const data = await getBotMsgs();
    return data.data;
  }
);
export const getAllFunctions = createAsyncThunk(
  "botSlice/getAllFunctions",
  async () => {
    const data = await getFunctions();
    return data.data;
  }
);
export const botSlice = createSlice({
  name: "botSlice",
  initialState,
  reducers: {
    setPreviewImage(state, action: PayloadAction<string>) {
      state.previewImage = action.payload;
    },
    setComponentDrag(state, action: PayloadAction<boolean>) {
      state.componentDrag = action.payload;
    },
    setDrawLine(state, action: PayloadAction<string | undefined>) {
      state.drawLineFrom = action.payload;
    },
    addLine(state, action: PayloadAction<string>) {
      state.lines.push({ from: state.drawLineFrom!, to: action.payload });
    },
    deleteLine(state, action: PayloadAction<string>) {
      state.lines = state.lines.filter((line) => line.from !== action.payload);
    },
    addMsgBot(state, action: PayloadAction<MsgBot>) {
      state.msgsBot.push(action.payload);
    },

    updateMsgBot(state, action: PayloadAction<MsgBot>) {
      const index = state.msgsBot.findIndex(
        (msg) => msg.id === action.payload.id
      );
      const msg = action.payload;

      state.msgsBot[index] = msg;
      const lines: Array<{ from: string; to: string }> = [];
      state.msgsBot.forEach((msg) => {
        if (msg.isFirstMsg) {
          lines.push({
            from: "incomingMsg",
            to: `input-${msg.id}`,
          });
        }
        if (msg.toMsg) {
          lines.push({
            from: `output-${msg.id}`,
            to: `input-${msg.toMsg.id}`,
          });
        }
        if (msg.actions) {
          const actions = Object.entries(msg.actions);
          actions.forEach(([key, value]) => {
            lines.push({
              from: `output-${msg.id}-${key}`,
              to: `input-${value}`,
            });
          });
        }
      });
      state.lines = lines;
    },
    deleteMsgBot(state, action: PayloadAction<number>) {
      state.msgsBot = state.msgsBot.filter((msg) => msg.id !== action.payload);
      const lines: Array<{ from: string; to: string }> = [];
      state.msgsBot.forEach((msg) => {
        if (msg.isFirstMsg) {
          lines.push({
            from: "incomingMsg",
            to: `input-${msg.id}`,
          });
        }
        if (msg.toMsg) {
          lines.push({
            from: `output-${msg.id}`,
            to: `input-${msg.toMsg.id}`,
          });
        }
        if (msg.actions) {
          const actions = Object.entries(msg.actions);
          actions.forEach(([key, value]) => {
            lines.push({
              from: `output-${msg.id}-${key}`,
              to: `input-${value}`,
            });
          });
        }
      });
      state.lines = lines;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getAllBotMsgs.fulfilled, (state, action) => {
        state.msgsBot = action.payload;
        const lines: Line[] = [];
        action.payload.forEach((msg) => {
          if (msg.isFirstMsg) {
            lines.push({
              from: "incomingMsg",
              to: `input-${msg.id}`,
            });
          }
          if (msg.toMsg) {
            lines.push({
              from: `output-${msg.id}`,
              to: `input-${msg.toMsg.id}`,
            });
          }
          if (msg.actions) {
            const actions = Object.entries(msg.actions);
            actions.forEach(([key, value]) => {
              lines.push({
                from: `output-${msg.id}-${key}`,
                to: `input-${value}`,
              });
            });
          }
        });
        state.lines = lines;
      })
      .addCase(getAllFunctions.fulfilled, (state, action) => {
        state.functions = action.payload;
      });
  },
});

export const previewImage = (state: RootState) => state.botSlice.previewImage;
export const drawLineFrom = (state: RootState) => state.botSlice.drawLineFrom;
export const lines = (state: RootState) => state.botSlice.lines;
export const msgsBot = (state: RootState) => state.botSlice.msgsBot;
export const componentDrag = (state: RootState) => state.botSlice.componentDrag;
export const functions = (state: RootState) => state.botSlice.functions;
export const msgBot = (state: RootState, id: number) =>
  state.botSlice.msgsBot.find((msg) => msg.id === id);

export const addNewLine =
  (to: string): AppThunk =>
  (dispatch, getState) => {
    const toId = to.replace("input-", "");

    const lineFromIds = drawLineFrom(getState())
      ?.replace("output-", "")
      .split("-");
    const botFrom = { ...msgBot(getState(), +lineFromIds![0])! };
    const botTo = { ...msgBot(getState(), +toId)! };

    if (lineFromIds![0] === "incomingMsg") {
      socket.emit(
        "updateBot",
        { ...botTo, isFirstMsg: true },
        (response: MsgBot) => {
          dispatch(updateMsgBot(response));
        }
      );
    } else {
      if (lineFromIds?.length && lineFromIds?.length > 1) {
        const actions = botFrom.actions ? { ...botFrom.actions } : {};

        actions[lineFromIds[1].toString()] = botTo.id;
        botFrom.actions = actions;
      } else if (lineFromIds?.length && lineFromIds?.length === 1) {
        botFrom.toMsg = botTo;
      }
      socket.emit("updateBot", botFrom, (response: MsgBot) => {
        dispatch(updateMsgBot(response));
      });
    }

    dispatch(addLine(to));
  };

export const handleDeleteLine =
  (lineFrom: Array<string>): AppThunk =>
  (dispatch, getState) => {
    const linesFromIds = lineFrom.map((line) =>
      line.replace("output-", "").split("-")
    );

    const bots: Array<MsgBot> = [];

    linesFromIds.forEach((line) => {
      const botIndex = bots.findIndex((bot) => bot.id === +line![0]);
      const botFrom = {
        ...msgBot(getState(), +line![0])!,
        ...(botIndex !== -1 ? bots[botIndex] : {}),
      };
      const msg = msgsBot(getState()).find((msg) => msg.isFirstMsg);
      if (line![0] === "incomingMsg") {
        bots.push({ ...msg, isFirstMsg: false } as any);
      } else {
        if (line?.length && line?.length > 1) {
          const actions = botFrom.actions ? { ...botFrom.actions } : {};
          delete actions[line[1].toString()];
          botFrom.actions = Object.keys(actions).length ? actions : null;
        } else if (line?.length && line?.length === 1) {
          botFrom.toMsg = null;
        }
        if (botIndex !== -1) {
          bots[botIndex] = botFrom;
        } else {
          bots.push(botFrom);
        }

        dispatch(deleteLine("output-" + line.join("-")));
      }
    });
    bots.forEach((bot) => {
      socket.emit("updateBot", bot, (response: MsgBot) => {
        dispatch(updateMsgBot(response));
      });
    });
  };

export const handleDeleteBot =
  (bot: MsgBot): AppThunk =>
  (dispatch, getState) => {
    const allLines = lines(getState());
    const linesFrom: string[] = [];
    allLines.forEach((line) => {
      if (line.to === `input-${bot.id}`) {
        linesFrom.push(line.from);
      }
    });
    dispatch(handleDeleteLine(linesFrom));
    socket.emit("deleteBot", bot.id, () => {
      dispatch(deleteMsgBot(bot.id!));
    });
  };

export const {
  setPreviewImage,
  setDrawLine,
  addLine,
  addMsgBot,
  updateMsgBot,
  deleteLine,
  deleteMsgBot,
  setComponentDrag,
} = botSlice.actions;
export default botSlice.reducer;
