import { routeConfigs } from "@@app/configs/routesConfig";
import FuseUtils from "./FuseUtils";
import { useAppSelector } from "@@app/store/hooks";
import { selectUser } from "@@app/store/slices/userSlice";
import { Role } from "@@types/roles";
import { Navigate, useNavigate } from "react-router";
import { useEffect } from "react";
import history from "@history";

const RedirctToAuthPage = () => {
  const user = useAppSelector(selectUser);
  const pages = [
    ...FuseUtils.generateRoutesFromConfigs(routeConfigs, undefined),
  ];

  const userPages = pages
    .filter((page) => page.path !== "/sign-in")
    .filter((page) =>
      page.auth?.some((authority) =>
        user?.roles.includes(Role.ADMIN)
          ? true
          : user?.roles?.includes(authority as Role)
      )
    );
  useEffect(() => {
    if (userPages.length && user.roles.length) {
      setTimeout(() => history.push(userPages[0].path), 0);
    }
  }, []);

  return null;
};

export default RedirctToAuthPage;
