import { getCompanyProfileApi } from "@@services/CompanyProfile";
import { CompanyProfile } from "@@types/companyProfile";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

type CompanyProfileSlice = {
  data?: CompanyProfile;
};

export const getProfile = createAsyncThunk(
  "companyProfile/getProfile",
  async () => {
    const data = await getCompanyProfileApi();
    return data.data;
  }
);

const initialState: CompanyProfileSlice = {};

export const CompanyProfileSlice = createSlice({
  name: "companyProfile",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getProfile.fulfilled, (state, action) => {
      state.data = action.payload;
    });
  },
  selectors: {
    companyProfile: (companyProfile) => companyProfile.data,
  },
});

export const { companyProfile } = CompanyProfileSlice.selectors;
