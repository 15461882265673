import {
  HiOutlineColorSwatch,
  HiOutlineDesktopComputer,
  HiOutlineTemplate,
  HiOutlineViewGridAdd,
} from "react-icons/hi";
import { FaWhatsapp } from "react-icons/fa";
import { BiBroadcast, BiBarChartAlt } from "react-icons/bi";
import { HiOutlineUsers } from "react-icons/hi2";
import { LuMessagesSquare } from "react-icons/lu";
import { IoCalendarOutline, IoSettingsOutline } from "react-icons/io5";
import { TbMessageShare } from "react-icons/tb";
import { MdOutlineContactPhone } from "react-icons/md";
import { RiRobot2Line, RiOpenaiFill } from "react-icons/ri";
import { CgDatabase } from "react-icons/cg";
import { GrDatabase } from "react-icons/gr";
import { PiUsersFourLight } from "react-icons/pi";
import { BsBroadcast } from "react-icons/bs";
import { RiContactsLine } from "react-icons/ri";
import { TbBrandWechat } from "react-icons/tb";

export type NavigationIcons = Record<string, JSX.Element>;

export const navigationIcon: NavigationIcons = {
  chat: <FaWhatsapp />,
  singleMenu: <HiOutlineViewGridAdd />,
  collapseMenu: <HiOutlineTemplate />,
  groupSingleMenu: <HiOutlineDesktopComputer />,
  groupCollapseMenu: <HiOutlineColorSwatch />,
  broadcast: <BiBroadcast />,
  broadcastPage: <BsBroadcast />,
  users: <HiOutlineUsers />,
  templates: <LuMessagesSquare />,
  appointment: <IoCalendarOutline />,
  companyMessages: <TbMessageShare />,
  contacts: <MdOutlineContactPhone />,
  dashboard: <BiBarChartAlt />,
  bot: <RiRobot2Line />,
  coolcareRegisterWithout: <CgDatabase />,
  coolcareRegisterWith: <GrDatabase />,
  settings: <IoSettingsOutline />,
  contactsGroups: <PiUsersFourLight />,
  messages: <LuMessagesSquare />,
  gpt: <RiOpenaiFill />,

  consultations: <TbBrandWechat />,
};
