import FuseNavigation from "@fuse/core/FuseNavigation";
import clsx from "clsx";
import { useMemo } from "react";
import { useAppDispatch, useAppSelector } from "app/store/hooks";
import useThemeMediaQuery from "@fuse/hooks/useThemeMediaQuery";
import { FuseNavigationProps } from "@fuse/core/FuseNavigation/FuseNavigation";
import withSlices from "app/store/withSlices";
import { navigationSlice, selectNavigation } from "./store/navigationSlice";
import { navbarCloseMobile } from "../navbar/navbarSlice";
import { settingsData } from "@@app/store/slices/settings";

/**
 * Navigation
 */

type NavigationProps = Partial<FuseNavigationProps>;

function Navigation(props: NavigationProps) {
  const { className = "", layout = "vertical", dense, active } = props;

  const navigation = useAppSelector(selectNavigation);
  const settings = useAppSelector(settingsData);

  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down("lg"));

  const dispatch = useAppDispatch();

  return useMemo(() => {
    function handleItemClick() {
      if (isMobile) {
        dispatch(navbarCloseMobile());
      }
    }

    return (
      <FuseNavigation
        className={clsx("navigation flex-1", className)}
        navigation={
          settings.showConsultation
            ? navigation
            : navigation.filter((nav) => nav.id !== "apps.consultations")
        }
        layout={layout}
        dense={dense}
        active={active}
        onItemClick={handleItemClick}
        checkPermission
      />
    );
  }, [
    dispatch,
    isMobile,
    navigation,
    active,
    className,
    dense,
    layout,
    settings,
  ]);
}

export default withSlices<NavigationProps>([navigationSlice])(Navigation);
