import BaseService from "@@services/BaseService";
import { BaseQueryFn, createApi } from "@reduxjs/toolkit/query/react";
import Axios, { AxiosError, AxiosRequestConfig } from "axios";

const axiosBaseQuery =
  (): BaseQueryFn<AxiosRequestConfig<unknown>, unknown, AxiosError> =>
  async ({ url, method, data, params, headers }) => {
    try {
      const result = await BaseService({
        url: url,
        method,
        data,
        params,
        headers,
      });
      return { data: result.data };
    } catch (axiosError) {
      const error = axiosError as AxiosError;
      return {
        error,
      };
    }
  };

export const apiService = createApi({
  baseQuery: axiosBaseQuery(),
  endpoints: () => ({}) as any,
  reducerPath: "apiService",
});

export default apiService;
