import {
  ChildLayout,
  ChildLayoutType,
  FormChild,
  FormType,
  Screen,
} from "@@types/flow";
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";
import { RootState } from "../store";

export type FlowSliceState = {
  screens: Array<Screen>;
  activeScreenId?: string;
};

const initialState: FlowSliceState = {
  screens: [
    {
      title: "New Screen",
      terminal: true,
      id: uuidv4(),
      layout: {
        type: "SingleColumnLayout",
        children: [
          {
            type: ChildLayoutType.Footer,
            label: "footer button",
            "on-click-action": {
              name: "navigate",
              next: {
                type: "screen",
                name: "NEXT_SCREEN",
              },
              payload: {},
            },
          },
        ],
      },
    },
  ],
};
export const flowSlice = createSlice({
  name: "flowSlice",
  initialState,
  reducers: {
    addNewScreen(state, action: PayloadAction<string>) {
      const prevScreen = state.screens[state.screens.length - 1];

      const id = uuidv4();
      const footer = prevScreen.layout.children.find(
        (child) => child.type === ChildLayoutType.Footer
      );
      if (footer) {
        footer["on-click-action"] = {
          name: "navigate",
          next: {
            type: "screen",
            name: id,
          },
          payload: {},
        };
      }
      state.screens.push({
        title: action.payload,
        terminal: true,
        id,
        layout: {
          type: "SingleColumnLayout",
          children: [
            {
              type: ChildLayoutType.Footer,
              label: "footer button",
              "on-click-action": {
                name: "complete",
                payload: {},
              },
            },
          ],
        },
      });
    },
    setActiveScreenId: (state, action: PayloadAction<string>) => {
      state.activeScreenId = action.payload;
    },
    deleteScreen(state, action: PayloadAction<string>) {
      state.screens = state.screens.filter(
        (screen) => screen.id !== action.payload
      );
    },
    updateScreenTitle: (state, action: PayloadAction<string>) => {
      const index = state.screens.findIndex(
        (screen) => screen.id === state.activeScreenId
      );
      state.screens[index].title = action.payload;
    },
    updateScreenFooterTitle: (state, action: PayloadAction<string>) => {
      const index = state.screens.findIndex(
        (screen) => screen.id === state.activeScreenId
      );
      const footer = state.screens[index].layout.children.find(
        (child) => child.type === ChildLayoutType.Footer
      );
      if (footer) {
        footer.label = action.payload;
      }
    },
    updateFlowItem: (
      state,
      action: PayloadAction<{
        value: Partial<ChildLayout> | Partial<FormChild>;
        childLayountIndex: number;
        formItemIndex?: number;
      }>
    ) => {
      const screen = state.screens.find(
        (screen) => screen.id === state.activeScreenId
      ) as Screen;
      const payload = action.payload;

      const layoutChild = screen.layout.children[payload.childLayountIndex];

      if (screen && layoutChild) {
        if (payload.formItemIndex) {
          const formItem = (layoutChild.children as Array<FormChild>)[
            payload.formItemIndex
          ];
          if (formItem) {
            (layoutChild.children as Array<FormChild>)[payload.formItemIndex] =
              {
                ...layoutChild.children?.[payload.formItemIndex],
                ...payload.value,
              } as FormChild;
          }
        } else {
          screen.layout.children[payload.childLayountIndex] = {
            ...layoutChild,
            ...payload.value,
          } as ChildLayout;
        }
      }
    },
    deleteFlowItem(
      state,
      action: PayloadAction<{
        childLayountIndex: number;
        formItemIndex?: number;
      }>
    ) {
      const screen = state.screens.find(
        (screen) => screen.id === state.activeScreenId
      ) as Screen;
      const payload = action.payload;

      const layoutChild = screen.layout.children[payload.childLayountIndex];

      if (screen && layoutChild) {
        if (payload.formItemIndex) {
          const formItem = (layoutChild.children as Array<FormChild>)[
            payload.formItemIndex
          ];
          if (formItem) {
            (layoutChild.children as Array<FormChild>).splice(
              payload.formItemIndex,
              1
            );
          }
        } else {
          screen.layout.children.splice(payload.childLayountIndex, 1);
        }
      }
    },
    addFormComponent(state, action: PayloadAction<ChildLayoutType | FormType>) {
      const activeScreen = state.activeScreenId;
      const activeScreenIndex = state.screens.findIndex(
        (screen) => screen.id === activeScreen
      );

      const childList = Object.values(ChildLayoutType);
      if (childList.includes(action.payload as ChildLayoutType)) {
        switch (action.payload as ChildLayoutType) {
          case ChildLayoutType.TextHeading:
          case ChildLayoutType.TextSubheading:
          case ChildLayoutType.TextBody:
          case ChildLayoutType.TextCaption: {
            state.screens[activeScreenIndex].layout.children.push({
              type: action.payload as ChildLayoutType,
              text: "",
            });
            break;
          }
          case ChildLayoutType.Image: {
            state.screens[activeScreenIndex].layout.children.push({
              type: action.payload as ChildLayoutType,
              src: "",
            });
            break;
          }
        }
      } else {
        const childrenLength =
          state.screens[activeScreenIndex].layout.children.length;

        const children = state.screens[activeScreenIndex].layout.children;

        let formItem: any;

        switch (action.payload as FormType) {
          case FormType.TextInput:
          case FormType.TextArea: {
            formItem = {
              type: action.payload as FormType,
              label: "",
              name: "",
              required: false,
            };
            break;
          }
          case FormType.CheckboxGroup:
          case FormType.Dropdown:
          case FormType.RadioButtonsGroup: {
            formItem = {
              type: action.payload as FormType,
              name: "",
              required: false,
              label: "",
              "data-source": [
                {
                  id: uuidv4(),
                  title: "item 1",
                },
                {
                  id: uuidv4(),
                  title: "item 2",
                },
              ],
            };
            break;
          }
          case FormType.OptIn: {
            formItem = {
              type: action.payload as FormType,
              name: "",
              required: false,
              label: "",
            };
            break;
          }
          case FormType.DatePicker: {
            formItem = {
              type: action.payload as FormType,
              name: "",
              label: "",
              required: false,
            };
            break;
          }
          case FormType.EmbeddedLink:
        }

        const lastChild =
          state.screens[activeScreenIndex].layout.children[childrenLength - 1];

        if (!lastChild || lastChild.type !== ChildLayoutType.Form) {
          children.push({
            type: ChildLayoutType.Form,
            children: [formItem],
          });
        } else {
          lastChild.children?.push(formItem);
        }
      }
    },
  },
});

export const flowScreens = (state: RootState) => state.flowSlice.screens;
export const activeScreenId = (state: RootState) =>
  state.flowSlice.activeScreenId;

export const activeScreen = createSelector(
  [activeScreenId, flowScreens],
  (activeScreenId, flowScreens) =>
    flowScreens.find((screen) => screen.id === activeScreenId)
);

export const activeScreenLength = createSelector(
  [activeScreenId, flowScreens],
  (activeScreenId, flowScreens) => {
    const screen = flowScreens.find((screen) => screen.id === activeScreenId);
    let length = 0;

    screen?.layout.children.forEach((child) => {
      if (child.type === ChildLayoutType.Form) {
        length += child!.children!.length;
      } else {
        length++;
      }
    });

    return length;
  }
);

export const activeScreenFooter = createSelector(
  [activeScreenId, flowScreens],
  (activeScreenId, flowScreens) => {
    const screen = flowScreens.find((screen) => screen.id === activeScreenId);

    return screen?.layout.children.find(
      (child) => child.type === ChildLayoutType.Footer
    );
  }
);

export const formItemByIndex = (
  state: RootState,
  childLayountIndex: number,
  formItemIndex?: number
) => {
  const activeScreen = state.flowSlice.activeScreenId;
  const screen = state.flowSlice.screens.find(
    (screen) => screen.id === activeScreen
  );
  const screenChildren = screen?.layout.children as Array<ChildLayout>;

  if (formItemIndex !== undefined) {
    return screenChildren[childLayountIndex].children![formItemIndex];
  }
  return screenChildren[childLayountIndex];
};

export const {
  addNewScreen,
  setActiveScreenId,
  deleteScreen,
  addFormComponent,
  updateScreenTitle,
  updateScreenFooterTitle,
  updateFlowItem,
  deleteFlowItem,
} = flowSlice.actions;

export default flowSlice.reducer;
