import { ContactsGroup } from '@/@types/contactsGroup'
import ApiService from './ApiService'

export const createContactsGroups = async (data: any) => {
    return ApiService.fetchData<ContactsGroup>({
        url: `/contacts-groups`,
        method: 'post',
        data,
    })
}
export const getContactsGroups = async () => {
    return ApiService.fetchData<ContactsGroup[]>({
        url: `/contacts-groups`,
        method: 'get',
    })
}
