import { createListenerMiddleware, isAnyOf } from "@reduxjs/toolkit";
import {
  createTeamMsg,
  updateMsgAfterSend,
  setSlectedTeamChat,
  reciveMsg,
  getSelectedTeamChat,
  zeroingUnReadTeamMsgs,
  incrementUnReadTeamMsg,
} from "../slices/teamChat";
import { selectUser } from "../slices/userSlice";
import { socket } from "@@socket/webSocket";
import { readTeamMessagesApi } from "@@services/TeamChatService";
import { RootState } from "../store";

export const teamChatListener = createListenerMiddleware();

teamChatListener.startListening({
  matcher: isAnyOf(createTeamMsg, setSlectedTeamChat, reciveMsg),
  effect: async (action, { dispatch, getState }) => {
    const state = getState() as RootState;
    const user = selectUser(state);
    const active = getSelectedTeamChat(state);

    const payload = action.payload as any;

    switch (action.type) {
      case createTeamMsg.type:
        socket.emit(
          "sendTeamMessage",
          { ...payload, to: payload.to.id },
          (val: any) => {
            dispatch(updateMsgAfterSend({ ...val, localId: payload.id }));
          }
        );
        break;
      case setSlectedTeamChat.type:
        if (payload?.unReadCount) {
          readTeamMessagesApi(payload.user.id);
          dispatch(zeroingUnReadTeamMsgs(payload.user.id));
        }
        break;
      case reciveMsg.type:
        if (payload.from.id == active?.user?.id) {
          readTeamMessagesApi(active.user.id);
        } else {
          dispatch(incrementUnReadTeamMsg(payload.from.id));
        }
        break;
    }
  },
});
