import { useAppDispatch, useAppSelector } from "@@app/store/hooks";
import { configData, getConfig } from "@@app/store/slices/settings";
import { useEffect } from "react";

const useConfig = () => {
  const config = useAppSelector(configData);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getConfig());
  }, []);

  useEffect(() => {
    if (config) {
      const icon = document.querySelector('link[rel="shortcut icon"]') as any;
      if (config.product === "rasael" || config.product === "rasael-pro") {
        icon.href = "/rasael.ico";
      } else if (config.product === "whatsbot") {
        icon.href = "/whatsbot.ico";
      }
    }
  }, [config]);
};

export default useConfig;
