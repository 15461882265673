// eslint-disable-next-line import/no-named-as-default
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./lang/en.json";
import ar from "./lang/ar.json";
import { Locale, enUS, ar as arLocale } from "date-fns/locale";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

const resources = {
  ar: {
    translation: ar,
  },
  en: {
    translation: en,
  },
};
const DETECTION_OPTIONS = {
  order: ["localStorage", "navigator"],
  caches: ["localStorage"],
};

export const availableLanguages = Object.keys(resources);

i18n
  .use(Backend)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    // lng: localStorage.getItem("language") || "en",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
    detection: DETECTION_OPTIONS,
  });

export const localeMapper: { [key: string]: Locale } = {
  en: enUS,
  ar: arLocale,
};
export default i18n;
