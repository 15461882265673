import {
  createBroadcastService,
  getBroadcastMsgsListService,
  getBroadcastsService,
  sendBroadcastMsg,
  updateBroadcastService,
} from "@@services/BroadCastServices";
import { Broadcast, BroadcastMsg } from "@@types/broadcast";
import { Message, MessageStatus } from "@@types/message";
import { MsgModal } from "@@types/msgModal";
import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";
import { RootState } from "../store";

export type BroadcastSlice = {
  openBroadcastList: boolean;
  broadcastMsgsList: Array<BroadcastMsg>;
  activeBroadcasMsgId?: number;
  msgModal?: MsgModal;
  showAddNewForm: boolean;
  openDrawer: boolean;
  disableFetch: boolean;
  fetchLoading: boolean;
  bulkMessages: Message[];
  status?: MessageStatus | "replied" | "optout" | "optoutOld";
  page: number;
};
export const createBulk = createAsyncThunk(
  "broadcastSlice/createBulk",
  async (data: Broadcast) => {
    const response = await createBroadcastService({
      ...data,
      contacts: JSON.stringify(data.contacts.map((c) => c.id)),
    });
    return response.data as Broadcast;
  }
);
export const getBroadcastMsgsList = createAsyncThunk(
  "broadcastSlice/getBroadcastMsgsList",
  async (
    data: { id: number; status: MessageStatus | "replied" | "optout" },
    { dispatch }
  ) => {
    dispatch(handleOpenDrawer(true));
    dispatch(setStatus(data.status));
    const response = await getBroadcastMsgsListService(data.id, data.status);
    return response.data;
  }
);
export const updateBulk = createAsyncThunk(
  "broadcastSlice/updateBulk",
  async (data: {
    id: number;
    isActive: boolean;
    sendDateTime: Date | null;
  }) => {
    const response = await updateBroadcastService({
      ...data,
    });
    return response.data;
  }
);
export const getMsgsBulks = createAsyncThunk(
  "broadcastSlice/getMsgsBulks",
  async (page: number) => {
    const response = await getBroadcastsService(page);
    return response.data;
  }
);
export const sendMsgBulks = createAsyncThunk(
  "broadcastSlice/sendMsgBulks",
  async (data: {
    templateMessage: number;
    contactsGroup: number;
    sendDateTime?: string;
    selectedLanguage?: string;
    message_from: string;
  }) => {
    const response = await sendBroadcastMsg(data);
    return response.data;
  }
);

const initialState: BroadcastSlice = {
  openBroadcastList: true,
  broadcastMsgsList: [],
  showAddNewForm: false,
  bulkMessages: [],
  openDrawer: false,
  disableFetch: false,
  fetchLoading: false,
  page: 1,
};

export const broadcastSlice = createSlice({
  name: "broadcastSlice",
  initialState,
  reducers: {
    handleOpenList(state, action: PayloadAction<boolean>) {
      state.openBroadcastList = action.payload;
    },
    handleOpenDrawer(state, action: PayloadAction<boolean>) {
      state.openDrawer = action.payload;
    },
    setShowAddNewForm(state, action) {
      state.showAddNewForm = action.payload;
    },
    setStatus(state, action) {
      state.status = action.payload;
    },
    updateBroadcastMsg(state, action: PayloadAction<BroadcastMsg>) {
      if (action.payload.id) {
        const index = state.broadcastMsgsList.findIndex(
          (chat) => chat.id === action.payload.id
        );
        state.broadcastMsgsList[index] = {
          ...action.payload,
          // createdAt: new Date().toISOString(),
        };
      } else {
        state.broadcastMsgsList.push({
          ...action.payload,
          // createadAt: new Date().toISOString(),
          // id: uuidv4(),
        });
      }
    },
    newBroadcastMsg(state, action: PayloadAction<BroadcastMsg>) {
      state.broadcastMsgsList.unshift(action.payload);
    },
    setBroadcast(state, action: PayloadAction<number | undefined>) {
      state.activeBroadcasMsgId = action.payload;
    },
    setMsgModalBroadcast(state, action: PayloadAction<MsgModal | undefined>) {
      state.msgModal = action.payload;
    },

    addMessageBroadcast(state, action: PayloadAction<Partial<BroadcastMsg>>) {
      // const index = state.broadcastMsgsList.findIndex(
      //     (chat) => chat.id === state.activeBroadcastId,
      // )
      // state.broadcastList[index].messages.unshift({
      //     ...action.payload,
      //     id: uuidv4(),
      //     status: 'wait',
      //     createdAt: new Date().toISOString(),
      //     readBy: 1,
      //     deliveredTo: 1,
      //     contacts: [
      //         {
      //             id: uuidv4(),
      //             name: 'name1',
      //             personalInfo: { phoneNumber: '1234567' },
      //         },
      //     ],
      // } as BroadcastMsg)
    },
  },
  extraReducers: (bulder) => {
    bulder
      .addCase(createBulk.fulfilled, (state, action) => {
        // state.broadcastMsgsList.push({
        //     ...action.payload,
        // })
      })
      .addCase(getMsgsBulks.pending, (state, action) => {
        state.fetchLoading = true;
      })
      .addCase(getMsgsBulks.fulfilled, (state, action) => {
        state.broadcastMsgsList = [
          ...state.broadcastMsgsList,
          ...action.payload,
        ];
        if (action.payload.length < 20) {
          state.disableFetch = true;
        }
        state.fetchLoading = false;
      })
      .addCase(sendMsgBulks.fulfilled, (state, action) => {
        state.broadcastMsgsList.unshift(action.payload);
        state.activeBroadcasMsgId = action.payload.id;
      })
      .addCase(updateBulk.fulfilled, (state, action) => {
        const index = state.broadcastMsgsList.findIndex(
          (msg) => msg.id === action.payload.id
        );
        state.broadcastMsgsList[index] = action.payload;
      })

      .addCase(getBroadcastMsgsList.pending, (state) => {
        state.bulkMessages = [];
      })
      .addCase(getBroadcastMsgsList.fulfilled, (state, action) => {
        const data = action.payload;
        state.bulkMessages = data;
      });
  },
});

export const openBroadcastList = (state: RootState) =>
  state.broadcastSlice.openBroadcastList;

export const disableFetch = (state: RootState) =>
  state.broadcastSlice.disableFetch;

export const fetchLoading = (state: RootState) =>
  state.broadcastSlice.fetchLoading;

export const broadcastMsgsList = (state: RootState) =>
  state.broadcastSlice.broadcastMsgsList;

export const showAddNewForm = (state: RootState) =>
  state.broadcastSlice.showAddNewForm;

export const pageNumber = (state: RootState) => state.broadcastSlice.page;

export const openDrawer = (state: RootState) => state.broadcastSlice.openDrawer;

export const activeBroadcastId = (state: RootState) =>
  state.broadcastSlice.activeBroadcasMsgId;

export const bulkMessages = (state: RootState) =>
  state.broadcastSlice.bulkMessages;

export const selectedStatus = (state: RootState) => state.broadcastSlice.status;

export const activeBroadcasMsg = createSelector(
  [broadcastMsgsList, activeBroadcastId],
  (broadcastMsgsList, activeBroadcastId) => {
    return broadcastMsgsList.find(
      (broadcast) => broadcast.id === activeBroadcastId
    );
  }
);

export const msgModalBroadcast = (state: RootState) =>
  state.broadcastSlice.msgModal;

export const {
  handleOpenList,
  setBroadcast,
  setMsgModalBroadcast,
  updateBroadcastMsg,
  addMessageBroadcast,
  setShowAddNewForm,
  handleOpenDrawer,
  setStatus,
  newBroadcastMsg,
} = broadcastSlice.actions;
export default broadcastSlice.reducer;
