import { read, utils, write } from 'xlsx'

export const createExcelFile = async (data: any[]) => {
    // Convert your data to a worksheet
    const worksheet = utils.json_to_sheet(data)

    // Create a new workbook and append the worksheet
    const workbook = utils.book_new()
    utils.book_append_sheet(workbook, worksheet, 'Sheet1')

    // Generate the Excel file
    const excelBuffer = write(workbook, { bookType: 'xlsx', type: 'array' })
    return new Blob([excelBuffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })
}
