import { Role } from "@@types/roles";
import { lazy } from "react";

const BotEditorApp = lazy(() => import("./BotEditorApp"));

/**
 * The chat app config.
 */
const BotEditorAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "bot",
      element: <BotEditorApp />,
      auth: [Role.BOT_PAGE],
    },
  ],
};

export default BotEditorAppConfig;
