export enum FormType {
    TextInput = 'TextInput',
    TextArea = 'TextArea',
    CheckboxGroup = 'CheckboxGroup',
    RadioButtonsGroup = 'RadioButtonsGroup',
    OptIn = 'OptIn',
    Dropdown = 'Dropdown',
    EmbeddedLink = 'EmbeddedLink',
    DatePicker = 'DatePicker',
}

export type FormChild = {
    type: FormType
    required: boolean
    label: string
    name: string
    width?: number
    height?: number
    'min-date'?: string
    'max-date'?: string
    'unavailable-dates'?: Array<string>
    'data-source'?: Array<{
        id: string
        title: string
    }>
    'on-select-action'?: {
        name: string
        payload: any
        next?: {
            type: string
            name: string
        }
    }
}
export enum ChildLayoutType {
    TextHeading = 'TextHeading',
    TextSubheading = 'TextSubheading',
    TextBody = 'TextBody',
    TextCaption = 'TextCaption',
    Footer = 'Footer',
    Form = 'Form',
    Image = 'Image',
}

export type ChildLayout = {
    type: ChildLayoutType
    children?: Array<FormChild>
    text?: string
    label?: string
    visible?: boolean
    src?: string
    'on-click-action'?: {
        name: string
        payload: any
        next?: {
            type: string
            name: string
        }
    }
}

export type FlowLayout = {
    type: 'SingleColumnLayout'
    children: Array<ChildLayout>
}

export type Screen = {
    id: string
    terminal: boolean
    title: string
    layout: FlowLayout
}
