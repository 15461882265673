import { getFile } from "@@services/ChatService";

export const getAudioDuration = async (
  src: string | File | Blob
): Promise<string> => {
  let fileSrc = src;
  if (typeof src !== "string") {
    fileSrc = URL.createObjectURL(src);
  } else {
    fileSrc = await getFile(src);
  }

  return new Promise((resolve, reject) => {
    const audio = new Audio(fileSrc as string);
    audio.onloadedmetadata = () => {
      const newMillis = audio.duration * 1000;
      let seconds: string | number = Math.floor((newMillis / 1000) % 60);
      const minutes = Math.floor((newMillis / (1000 * 60)) % 60);
      seconds = seconds < 10 ? "0" + seconds : seconds;

      resolve(minutes + ":" + seconds);
      audio.remove();
    };
    audio.onerror = () => {
      reject("Error loading audio");
    };
  });
};
