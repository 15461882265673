import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getMsgsFiltersService } from "@@services/MessagesServices";
import { Filters } from "@@types/filters";
import { RootState } from "../store";
import {
  createConversationTypeApi,
  deleteConversationTypeApi,
  getAllConversationTypeApi,
  updateConversationTypeApi,
} from "@@services/MessagesTypesServices";
import { ConversationType } from "@@types/chat";

export type MessagesSlice = {
  filters: Filters & { tags: string[] };
  conversationTypes: Array<ConversationType>;
};
export const getMessagesFilters = createAsyncThunk(
  "messagesSlice/getMessages",
  async (_: undefined, { getState }) => {
    const { limit, page, from, sort, to, search } = messagesFilters(
      getState() as RootState
    );

    const response = await getMsgsFiltersService(
      page,
      limit,
      from ? from : "",
      to ? to : "",
      sort ?? "",
      search
    );

    return response.data;
  }
);
export const getConversationsTypes = createAsyncThunk(
  "messagesSlice/getConversationsTypes",
  async () => {
    const response = await getAllConversationTypeApi();

    return response.data;
  }
);
export const createConversationtypes = createAsyncThunk(
  "messagesSlice/createConversationtypes",
  async (data: ConversationType) => {
    const response = await createConversationTypeApi(data);

    return response.data;
  }
);
export const updateConversationtypes = createAsyncThunk(
  "messagesSlice/updateConversationtypes",
  async (data: ConversationType) => {
    const response = await updateConversationTypeApi(data);

    return response.data;
  }
);
export const deleteConversationtypes = createAsyncThunk(
  "messagesSlice/deleteConversationtypes",
  async (id: number) => {
    const response = await deleteConversationTypeApi(id);

    return id;
  }
);

const initialState: MessagesSlice = {
  filters: {
    page: 0,
    limit: 20,
    from: null,
    to: null,
    search: "",
    tags: [],
  },
  conversationTypes: [],
};

export const messagesSlice = createSlice({
  name: "messagesSlice",
  initialState,
  reducers: {
    setFilters(
      state,
      action: PayloadAction<Partial<Filters & { tags: string[] }>>
    ) {
      state.filters = {
        ...state.filters,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getConversationsTypes.fulfilled, (state, action) => {
        state.conversationTypes = action.payload;
      })
      .addCase(createConversationtypes.fulfilled, (state, action) => {
        state.conversationTypes.push(action.payload);
      })
      .addCase(updateConversationtypes.fulfilled, (state, action) => {
        const index = state.conversationTypes.findIndex(
          (msg) => msg.id === action.payload.id
        );
        if (index !== -1) {
          state.conversationTypes[index] = action.payload;
        }
      })
      .addCase(deleteConversationtypes.fulfilled, (state, action) => {
        state.conversationTypes = state.conversationTypes.filter(
          (msg) => msg.id !== action.payload
        );
      });
  },
});
export const messagesFilters = (state: RootState) =>
  state.messagesSlice.filters;
export const conversationTypes = (state: RootState) =>
  state.messagesSlice.conversationTypes;

export const { setFilters } = messagesSlice.actions;

export default messagesSlice.reducer;
