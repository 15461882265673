import { Role } from "@@types/roles";
import { lazy } from "react";

const ContactsGroupsApp = lazy(() => import("./ContactsGroupsApp"));

/**
 * The chat app config.
 */
const ContactsGroupsAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "contacts-groups",
      element: <ContactsGroupsApp />,
      auth: [Role.BULK_MESSAGES],
    },
  ],
};

export default ContactsGroupsAppConfig;
