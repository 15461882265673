import ApiService from "./ApiService";

export async function apiGetCrmCustomersStatisticCountries<T>() {
  return ApiService.fetchData<T>({
    url: "/chart/statistics",
    method: "get",
  });
}
export async function apiGetContactsLastHalfMonth<T>(data: {
  startDate: string;
  endDate: string;
}) {
  return ApiService.fetchData<T>({
    url: `/conversations/lastHalfMonth/?dateFrom=${data.startDate}&dateTo=${
      data.endDate
    }&timeZone=${Intl.DateTimeFormat().resolvedOptions().timeZone}`,
    method: "get",
    timeout: 0,
  });
}
export async function apiGetMsgsInDate<T>(start?: string, end?: string) {
  return ApiService.fetchData<T>({
    url: `messages/date/${start}/${end}/?timeZone=${
      Intl.DateTimeFormat().resolvedOptions().timeZone
    }`,
    method: "get",
  });
}
export async function apiGetContactsInDate<T>(start?: string, end?: string) {
  return ApiService.fetchData<T>({
    url: `conversations/date/${start}/${end}/?timeZone=${
      Intl.DateTimeFormat().resolvedOptions().timeZone
    }`,
    method: "get",
  });
}
export async function apiGetRabshConversations<T>(
  messagesCount: number,
  start?: string,
  end?: string
) {
  return ApiService.fetchData<T>({
    url: `conversations/rabsh-conversations/?dateFrom=${start}&dateTo=${end}&messagesCount=${messagesCount}`,
    method: "get",
  });
}
export async function apiGetKeywords<T>() {
  return ApiService.fetchData<T>({
    url: `keywords`,
    method: "get",
  });
}
export async function apiDeleteKeywords(id: number) {
  return ApiService.fetchData({
    url: `keywords/${id}`,
    method: "delete",
  });
}

export async function apiUpdateKeywords<T>(id: number, keyword: string) {
  return ApiService.fetchData<T>({
    url: `keywords/${id}`,
    method: "put",
    data: { keyword },
  });
}
export async function apiAddKeywords<T>(keyword: string) {
  return ApiService.fetchData<T>({
    url: `keywords`,
    method: "post",
    data: { keyword },
  });
}
