import { GptType } from '@/@types/gpt'
import ApiService from './ApiService'

export const getAllGptApi = () => {
    return ApiService.fetchData<GptType[]>({
        url: '/gpt',
        method: 'get',
    })
}

export const updateGptApi = (data: GptType) => {
    return ApiService.fetchData<GptType>({
        url: `/gpt/${data.id}`,
        method: 'put',
        data,
    })
}

export const createGptApi = (data: GptType) => {
    return ApiService.fetchData<GptType>({
        url: `/gpt/`,
        method: 'post',
        data,
    })
}
export const deleteGptApi = (data: GptType) => {
    return ApiService.fetchData<GptType>({
        url: `/gpt/${data.id}`,
        method: 'delete',
    })
}
