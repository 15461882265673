import { Chat } from "@@types/chat";
import ApiService from "./ApiService";
import { Message } from "@@types/message";
import { Contact } from "@@types/contacts";
import { Filters } from "@@app/store/slices/chat";
import { Consultation } from "@@types/consultation";

export const getChatsService = async (
  date?: string,
  chatsIdFilter?: Array<number>,
  unRead?: boolean,
  consultation?: boolean
) => {
  const searchParams = new URLSearchParams();
  if (date) searchParams.append("date", date);
  if (chatsIdFilter)
    searchParams.append("chatsIdFilter", chatsIdFilter.join(","));
  if (unRead) searchParams.append("unRead", String(unRead));
  searchParams.append("consultation", String(!!consultation));

  searchParams.append("limit", "20");

  return ApiService.fetchData<Array<Chat>>({
    url: `/conversations?${searchParams.toString()}`,
    method: "get",
    timeout: 0,
  });
};
export const getMsgsService = async (
  conversationPhone?: string,
  msgId?: number,
  consultation?: boolean
) => {
  return ApiService.fetchData<Array<Message>>({
    url: `/messages/conversation/${conversationPhone}?msgId=${
      msgId ?? ""
    }&limit=20&consultation=${!!consultation ?? ""}`,
    method: "get",
  });
};
export const getConversationByPhoneService = async (
  conversationPhone?: string
) => {
  return ApiService.fetchData<Chat>({
    url: `/conversations/${conversationPhone}`,
    method: "get",
  });
};
export const finishedChatApi = async (conversationId?: number) => {
  return ApiService.fetchData<Chat>({
    url: `/conversations/${conversationId}/finished`,
    method: "get",
  });
};
export const getSearchMsgsService = async (
  conversationPhone?: string,
  msgMatch?: number
) => {
  return ApiService.fetchData<Array<Message>>({
    url: `/messages/search/conversation/${conversationPhone}?msgMatch=${
      msgMatch ?? ""
    }`,
    method: "get",
    timeout: 0,
  });
};
export const getSearchChatsService = async (
  {
    search,
    tags,
    dateFrom,
    dateTo,
    filterUnRead,
    finished,
    assignToMe,

    pageChatsSearch,
    pageContactsSearch,
    pageMessagesSearch,
    notAnswered,
    conversationsTypes,
  }: Filters,
  {
    disableChatSearchFectch,
    disableContactsSearchFectch,
    disableMessagesSearchFectch,
  }: any,
  consultations?: boolean
) => {
  const searchParams = new URLSearchParams();
  if (search) searchParams.append("search", search);

  if (dateFrom) searchParams.append("dateFrom", dateFrom);
  if (dateTo) searchParams.append("dateTo", dateTo);
  if (filterUnRead) searchParams.append("unRead", String(filterUnRead));
  if (finished) searchParams.append("finished", String(finished));
  if (assignToMe) searchParams.append("assignToMe", String(assignToMe));
  if (tags?.length) searchParams.append("tags", tags.join(","));
  if (conversationsTypes?.length)
    searchParams.append(
      "conversationsTypes",
      conversationsTypes.map((t) => t.id).join(",")
    );
  if (disableChatSearchFectch) searchParams.append("disableChatSearch", "true");
  if (consultations) searchParams.append("consultations", "true");
  if (notAnswered) searchParams.append("notAnswered", "true");
  if (disableContactsSearchFectch)
    searchParams.append("disableContactsSearch", "true");
  if (disableMessagesSearchFectch)
    searchParams.append("disableMessagesSearch", "true");

  searchParams.append("limit", "20");

  let page = 1;
  if (disableChatSearchFectch) {
    page = pageContactsSearch;
  }
  if (disableChatSearchFectch && disableContactsSearchFectch) {
    page = pageMessagesSearch;
  }
  if (!disableChatSearchFectch) {
    page = pageChatsSearch;
  }
  searchParams.append("page", page?.toString() ?? "1");

  return ApiService.fetchData<{
    conversations: Array<Chat>;
    contacts: Array<Contact>;
    messages: Array<Message>;
  }>({
    url: `/conversations/search/?${searchParams.toString()}`,
    method: "get",
    timeout: 0,
  });
};
export const readChatMsg = async (chat?: Chat) => {
  return ApiService.fetchData<Array<Message>>({
    url: `/conversations/readMsgs/${chat?.id}`,
    method: "get",
  });
};
export const getFile = async (path: string | File) => {
  if (typeof path !== "string") return URL.createObjectURL(path);

  const file = await ApiService.fetchData<Blob>({
    url: `/files/${path}`,

    method: "get",
    responseType: "blob",
    timeout: 0,
  });

  return URL.createObjectURL(file.data);
};

export const fetchBufferedVideo = async (
  path: string,
  rangeStart: number,
  rangeEnd: number
) => {
  try {
    const response = await ApiService.fetchData<Blob>({
      url: `/files/video/${path}`,

      method: "get",
      responseType: "blob",
      timeout: 0,
      headers: {
        Range: `bytes=${rangeStart}-${rangeEnd}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error fetching video:", error);
  }
};

export const assignToChat = async (
  conversationId: number,
  usersId: number[]
) => {
  return ApiService.fetchData({
    url: `/conversations/${conversationId}/assign-to`,
    method: "put",
    data: usersId,
  });
};

export const handleConsultationAnsweredApi = async (consultationId: number) => {
  return ApiService.fetchData<Consultation>({
    url: `/consultations/${consultationId}/markAsReplied`,
    method: "get",
  });
};

export const removeConversationTypes = async (conversationId: number) => {
  return ApiService.fetchData({
    url: `/conversations/${conversationId}/removeConversationTypes`,
    method: "get",
  });
};
export const updateConversationTypes = async (conversationId: number, data) => {
  return ApiService.fetchData({
    url: `/conversations/${conversationId}/updateConversationTypes`,
    method: "put",
    data,
  });
};
