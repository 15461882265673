import { Role } from "@@types/roles";
import { lazy } from "react";

const CalendarApp = lazy(() => import("./CalendarApp"));

/**
 * The Calendar App Config.
 */
const CalendarAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "appointment",
      element: <CalendarApp />,
      auth: [Role.APPOINTMENT],
    },
  ],
};

export default CalendarAppConfig;
