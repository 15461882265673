import { IconType } from 'react-icons/lib'

type MsgTypeProps = {
    showMsgType?: boolean
    icon: IconType
    msgType?: string
}
const MsgType = ({ showMsgType, msgType, icon: Icon }: MsgTypeProps) => {
    return (
        <div className="flex gap-2 items-center">
            <Icon />
            {showMsgType && !!msgType && <p>{msgType}</p>}
        </div>
    )
}

export default MsgType
