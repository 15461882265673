import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { TeamMember } from "@@types/teamMember";
import { v4 as uuidv4 } from "uuid";
import {
  addNewMemberApi,
  deleteMemberApi,
  getMembersApi,
  updateMemberApi,
} from "@@services/UsersService";
import { RootState } from "../store";

type Member = {
  id: string;
  name: string;
  email: string;
  img: string;
};

type Project = {
  id: number;
  name: string;
  category: string;
  desc: string;
  attachmentCount: number;
  totalTask: number;
  completedTask: number;
  progression: number;
  dayleft: number;
  status: string;
  member: Omit<Member, "id" | "email">[];
};

type ProjectList = Project[];

type Query = {
  sort: "asc" | "desc" | "";
  search: string;
};

type GetProjectListRequest = Query;

type GetProjectListResponse = ProjectList;

type PutProjectListRequest = {
  id: string;
  name: string;
  desc: string;
  totalTask?: number;
  completedTask?: number;
  progression: number;
  member?: Omit<Member, "email" | "id">[];
};

type PutProjectListResponse = ProjectList;

export type ProjectListState = {
  loading: boolean;
  teamMembers: TeamMember[];

  view: "grid" | "list";
  query: Query;
  newProjectDialog: boolean;
  selectedMember?: TeamMember;
};

export const SLICE_NAME = "teamList";

export const getTeamList = createAsyncThunk(
  SLICE_NAME + "/getList",
  async () => {
    const response = await getMembersApi();
    return response.data;
  }
);

export const updatememberData = createAsyncThunk(
  SLICE_NAME + "/updatememberData",
  async (data: TeamMember) => {
    const response = await updateMemberApi(data);
    return response.data;
  }
);
export const deleteMember = createAsyncThunk(
  SLICE_NAME + "/deleteMember",
  async (id: number) => {
    const response = await deleteMemberApi(id);
    return response.data;
  }
);
export const addMember = createAsyncThunk(
  SLICE_NAME + "/addNewMember",
  async (data: TeamMember) => {
    const response = await addNewMemberApi(data);
    return response.data;
  }
);

const initialState: ProjectListState = {
  loading: false,
  teamMembers: [],
  view: "grid",
  query: {
    sort: "asc",
    search: "",
  },
  newProjectDialog: false,
};

export const teamSlice = createSlice({
  name: `teamSlice`,
  initialState,
  reducers: {
    toggleView: (state, action) => {
      state.view = action.payload;
    },
    toggleSort: (state, action) => {
      state.query.sort = action.payload;
    },
    setSearch: (state, action) => {
      state.query.search = action.payload;
    },
    toggleNewMemberDialog: (state, action) => {
      state.newProjectDialog = action.payload;
    },
    setSelectedMember(state, action: PayloadAction<TeamMember | undefined>) {
      state.selectedMember = action.payload;
      state.newProjectDialog = true;
    },
    addNewMember(state, action: PayloadAction<TeamMember>) {
      state.teamMembers.push({
        ...action.payload,
        id: uuidv4() as unknown as number,
      });
    },
    updateMember(state, action: PayloadAction<TeamMember>) {
      const index = state.teamMembers.findIndex(
        (member) => member.id === action.payload.id
      );
      state.teamMembers[index] = action.payload;
      state.selectedMember = undefined;
      state.newProjectDialog = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addMember.fulfilled, (state, action) => {
        state.teamMembers.push(action.payload);
        state.loading = false;
      })
      .addCase(getTeamList.fulfilled, (state, action) => {
        state.teamMembers = action.payload;
        state.loading = false;
      })
      .addCase(deleteMember.fulfilled, (state, action) => {
        state.teamMembers = state.teamMembers.filter(
          (member) => member.id !== action.payload.id
        );
      })
      .addCase(updatememberData.fulfilled, (state, action) => {
        const index = state.teamMembers.findIndex(
          (member) => member.id === action.payload.id
        );
        state.teamMembers[index] = action.payload;
        state.loading = false;
      });
  },
});

export const selectedMember = (state: RootState) =>
  state.teamSlice.selectedMember;

export const teamMembers = (state: RootState) => state.teamSlice.teamMembers;

export const {
  toggleView,
  toggleSort,
  toggleNewMemberDialog,
  setSearch,
  setSelectedMember,
  addNewMember,
  updateMember,
} = teamSlice.actions;

export default teamSlice.reducer;
