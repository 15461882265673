import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

import { updateChatContact } from "./chat";
import { Filters } from "@@types/filters";
import { Contact, Tag } from "@@types/contacts";
import { TableQueries } from "@@types/common";
import {
  apiCreateContact,
  apiCreateTags,
  apiDeleteTags,
  apiGetContacts,
  apiGetCrmCustomersStatistic,
  apiGetTags,
  apiPutContact,
  apiUpdateTags,
} from "@@services/CrmService";
import { AppThunk, RootState } from "../store";

type Statistic = {
  value: number;
  growShrink: number;
};

type ContactStatistic = {
  count: number;
  countThisMounth: number;
};

export type ContactsFilter = {
  tags?: string[];
} & Filters;

type GetCrmContactsStatisticResponse = ContactStatistic;

export type ContactssState = {
  loading: boolean;
  tagsloading: boolean;
  disablePhone: boolean;
  statisticLoading: boolean;
  customerList: Contact[];
  statisticData: Partial<ContactStatistic>;
  tableData: TableQueries;
  filterData: ContactsFilter;
  drawerOpen: boolean;
  selectedCustomer?: Partial<Contact>;
  tags: Tag[];
};

export const SLICE_NAME = "crmContacts";

export const getCustomerStatistic = createAsyncThunk(
  "crmContacts/data/getContactStatistic",
  async () => {
    const response =
      await apiGetCrmCustomersStatistic<GetCrmContactsStatisticResponse>();
    return response.data;
  }
);

export const getContacts = createAsyncThunk(
  "crmCustomers/data/getCustomers",
  async () => {
    const response = await apiGetContacts<Contact[]>();
    return response.data;
  }
);

export const putContact = createAsyncThunk(
  "crmCustomers/data/putContact",
  async (data: Contact) => {
    const response = await apiPutContact<Contact>(data);
    return response.data;
  }
);
export const createContact = createAsyncThunk(
  "crmCustomers/data/createContact",
  async (data: Contact) => {
    const response = await apiCreateContact<Contact>(data);
    return response.data;
  }
);

export const getTags = createAsyncThunk(
  "crmCustomers/data/getTags",
  async () => {
    const response = await apiGetTags<Tag[]>();
    return response.data;
  }
);

export const createTag = createAsyncThunk(
  "crmCustomers/data/createTag",
  async (data: { tag: string }) => {
    const response = await apiCreateTags<Tag>(data);
    return response.data;
  }
);
export const updateTag = createAsyncThunk(
  "crmCustomers/data/updateTag",
  async (data: Tag) => {
    const response = await apiUpdateTags<Tag>(data);
    return response.data;
  }
);
export const deleteTag = createAsyncThunk(
  "crmCustomers/data/deleteTag",
  async (data: Tag) => {
    const response = await apiDeleteTags<Tag>(data);
    return response.data;
  }
);

export const initialTableData: TableQueries = {
  total: 0,
  pageIndex: 0,
  pageSize: 20,
  query: "",
  sort: {
    order: "",
    key: "",
  },
};

const initialState: ContactssState = {
  loading: false,
  tagsloading: false,
  statisticLoading: false,
  disablePhone: false,
  customerList: [],
  statisticData: {},
  tableData: initialTableData,
  filterData: {
    tags: [],
    search: "",
    page: 0,
    limit: 20,
    from: null,
    to: null,
  },
  drawerOpen: false,
  selectedCustomer: {},
  tags: [],
};

export const contactsSlice = createSlice({
  name: `contactsSlice`,
  initialState,
  reducers: {
    setTableData: (state, action) => {
      state.tableData = action.payload;
    },
    setCustomerList: (state, action) => {
      state.customerList = action.payload;
    },

    setFilters(state, action: PayloadAction<Partial<ContactsFilter>>) {
      state.filterData = {
        ...state.filterData,
        ...action.payload,
      };
    },
    setSelectedCustomer: (
      state,
      action: PayloadAction<Partial<Contact | undefined>>
    ) => {
      const data = state.customerList.find(
        (customer) => customer.id === action.payload?.id
      );
      state.selectedCustomer = data ? data : action.payload;
    },
    setDrawerOpen: (state) => {
      state.drawerOpen = true;
    },
    setDrawerClose: (state) => {
      state.drawerOpen = false;
    },
    setDisablePhone: (state, action: PayloadAction<boolean>) => {
      state.disablePhone = action.payload;
    },
    addNewContact(state, action: PayloadAction<Partial<Contact>>) {
      state.customerList = [
        ...state.customerList,
        {
          ...action.payload,
        } as Contact,
      ];
    },

    updateContactData(state, action: PayloadAction<Partial<Contact>>) {
      const index = state.customerList.findIndex(
        (customer) => customer.id === action.payload.id
      );
      if (index !== -1) {
        state.customerList[index] = {
          ...state.customerList[index],
          ...action.payload,
        };
      }
    },
    handleAddNewTag(state, action: PayloadAction<Tag>) {
      state.tags.push(action.payload);
    },
    handleDeleteTag(state, action: PayloadAction<Tag>) {
      state.tags = state.tags.filter((tag) => tag.id !== action.payload.id);
    },
    handleUpdateTag(state, action: PayloadAction<Tag>) {
      const index = state.tags.findIndex((tag) => tag.id === action.payload.id);
      if (index !== -1) {
        state.tags[index] = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(getCustomerStatistic.fulfilled, (state, action) => {
        state.statisticData = action.payload;
        state.statisticLoading = false;
      })
      .addCase(getCustomerStatistic.pending, (state) => {
        state.statisticLoading = true;
      })
      .addCase(putContact.fulfilled, (state, action) => {
        state.customerList = state.customerList.map((contact) =>
          contact.id === action.payload.id ? action.payload : contact
        );
      })
      .addCase(createContact.fulfilled, (state, action) => {
        state.customerList.push(action.payload);
      })
      .addCase(getTags.fulfilled, (state, action) => {
        state.tags = action.payload;
      });
  },
});

export const allContactData = (state: RootState) => {
  return [...state.contactsSlice.customerList].sort((a, b) =>
    (a.nickname || "").localeCompare(b.nickname || "")
  );
};
export const selectedCustomer = (state: RootState) =>
  state.contactsSlice.selectedCustomer;

export const tagsloading = (state: RootState) =>
  state.contactsSlice.tagsloading;

export const contactsTags = (state: RootState) => {
  return state.contactsSlice.tags;
};
export const filterData = (state: RootState) => {
  return state.contactsSlice.filterData;
};
export const disablePhone = (state: RootState) => {
  return state.contactsSlice.disablePhone;
};
export const contactsList = (state: RootState) => {
  return state.contactsSlice.customerList;
};

export const drawerOpen = (state: RootState) => {
  return state.contactsSlice.drawerOpen;
};

export const updateContact =
  (contact: Contact): AppThunk =>
  async (dispatch, state) => {
    dispatch(updateContactData(contact));
    dispatch(updateChatContact(contact));
  };

export const {
  setCustomerList,
  setFilters,
  setSelectedCustomer,
  setDrawerOpen,
  setDrawerClose,
  addNewContact,
  updateContactData,
  handleAddNewTag,
  setDisablePhone,
  handleDeleteTag,
  handleUpdateTag,
} = contactsSlice.actions;

export default contactsSlice.reducer;
