import {
  differenceInCalendarDays,
  format,
  isToday,
  isYesterday,
} from "date-fns";
import { useTranslation } from "react-i18next";
import useCurrentLang from "./useCurrentLang";
import { localeMapper } from "@@locales/i18n";

const useDateFormat = () => {
  const { t } = useTranslation();
  const currentLang = useCurrentLang();

  const customFormat = (date: Date, formatStr: string) => {
    return format(date, formatStr, { locale: localeMapper[currentLang] });
  };

  const dateFormat = (dateString?: string, showToday?: boolean) => {
    if (!dateString) return "";
    const date = new Date(dateString);

    const now = new Date();

    const diff = differenceInCalendarDays(now, date);

    const isTodayBoll = isToday(date);
    const isYesterdayBool = isYesterday(date);

    if (isTodayBoll && showToday) {
      return t("dateFormat.today");
    } else if (isTodayBoll && !showToday) {
      return customFormat(date, "h:mm a");
    } else if (isYesterdayBool) {
      return t("dateFormat.yesterday");
    } else if (!isTodayBoll && !isYesterdayBool && diff <= 6) {
      return customFormat(date, "EE");
    } else {
      return customFormat(date, "yy/MM/dd");
    }
  };

  const dateAtFormat = (dateString?: string) => {
    if (!dateAtFormat) return;
    const date = new Date(dateString as string);

    const now = new Date();

    const diff = differenceInCalendarDays(now, date);

    const isTodayBoll = isToday(date);
    const isYesterdayBool = isYesterday(date);

    if (isTodayBoll) {
      return `${t("dateFormat.today")} ${t(
        "dateFormat.at"
      )} ${customFormat(date, "h:mm a")}`;
    } else if (isYesterdayBool) {
      return `${t("dateFormat.yesterday")} ${t(
        "dateFormat.at"
      )} ${customFormat(date, "h:mm a")}`;
    } else if (!isTodayBoll && !isYesterdayBool && diff <= 6) {
      return `${customFormat(date, "EEEE")} ${t(
        "dateFormat.at"
      )} ${customFormat(date, "h:mm a")}`;
    } else {
      return `${customFormat(date, "yy/MM/dd")} ${t(
        "dateFormat.at"
      )} ${customFormat(date, "h:mm a")}`;
    }
  };

  return { dateFormat, dateAtFormat };
};

export default useDateFormat;
